import { useFetch } from 'hooks';
import { Employee, UseFetchOptions } from 'types';

export default {
  useFetchActive: (config: UseFetchOptions) =>
    useFetch<Employee[]>('/employee/get-active-list', {
      method: 'POST',
      ...config,
      mapper: (employee: Employee) => ({ ...employee, fullname: `${employee.name} ${employee.surname}` }),
    }),
  useFetchActiveByDepartmentId: (config: UseFetchOptions, departmentId: number) =>
    useFetch<Employee[]>('/employee/get-active-list-by-department-id', {
      method: 'POST',
      ...config,
      initParams: { departmentId },
      mapper: (employee: Employee) => ({ ...employee, fullname: `${employee.name} ${employee.surname}` }),
    }),
};
