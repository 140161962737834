import { Accordion, Checkbox, ComboBox, ComboBoxRemote, DatePicker, FileInput, Radio, RadioGroup, TextInput } from 'components';
import { evetHayir } from 'constants';
import { Form } from 'react-aria-components';
import {
  Account,
  AccountAgreement,
  AccountFormable,
  AccountGroup,
  AccountOwner,
  AccountPaymentType,
  AccountStatus,
  AccountType,
  AccountWorkingStatus,
  City,
  Contact,
  ContactTitle,
  ContactType,
  Country,
  Department,
  FormHook,
} from 'types';
import { IletisimKisiler } from './IletisimKisiler';

export interface FirmaBilgiProps {
  accountForm: FormHook<AccountFormable>;
  account?: Account;
  accountAgreements?: AccountAgreement[];
  accountGroup?: AccountGroup[];
  accountOwner?: AccountOwner[];
  accountPaymentType?: AccountPaymentType[];
  accountStatus?: AccountStatus[];
  accountType?: AccountType[];
  accountWorkingStatus?: AccountWorkingStatus[];
  representativeList?: Account[];
  subeList?: Account[];
  city?: City[];
  shippingStates?: City[];
  country?: Country[];
  department?: Department[];
  contactList?: Contact[];
  contactTitle?: ContactTitle[];
  contactType?: ContactType[];
  contactListLoading?: boolean;
}

export const FirmaBilgi = (props: FirmaBilgiProps) => {
  const {
    account,
    accountForm,
    accountAgreements,
    accountGroup,
    accountOwner,
    accountPaymentType,
    accountStatus,
    accountType,
    accountWorkingStatus,
    representativeList,
    subeList,
    city,
    shippingStates,
    country,
    department,
    contactList,
  } = props;

  return (
    <Form className="w-full grid grid-cols-3 gap-5 gap-x-8" autoComplete="off">
      <input autoComplete="false" name="hidden" type="text" className="hidden" />

      <TextInput {...accountForm.props('code')} type="text" label="Firma Kodu" isDisabled />
      <TextInput {...accountForm.props('tpeownerNumber')} type="text" label="TPMK Sahip Numarası" />
      <TextInput {...accountForm.props('title')} type="text" label="Firma Ünvanı" isRequired />
      <TextInput {...accountForm.props('oldTitle')} type="text" label="Eski Firma Ünvanı" />
      <ComboBox {...accountForm.props('accountOwner')} label="Firma Müşteri" defaultItems={accountOwner} itemKey="id" itemLabel="detail" />
      <ComboBox {...accountForm.props('accountGroup')} label="Şirket Grubu" defaultItems={accountGroup} itemKey="id" itemLabel="detail" />

      <ComboBox {...accountForm.props('accountType')} label="Firma Türü" defaultItems={accountType} itemKey="id" itemLabel="detail" isRequired />
      <ComboBox {...accountForm.props('representative')} label="Vekil Mi?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
      <ComboBoxRemote
        {...accountForm.props('workingRepresentative')}
        label="Aracı Vekil"
        url="/account/get-active-and-representative-list"
        searchKey="accountTitleOrAccountCode"
        itemKey="id"
        itemLabel="title"
        checkbox={<Checkbox {...accountForm.props('workingRepresentativeStatus')}></Checkbox>}
      />
      <ComboBoxRemote
        {...accountForm.props('branchAccount')}
        label="Şube"
        url="/account/get-list-for-combo"
        searchKey="accountTitleOrAccountCode"
        itemKey="id"
        itemLabel="title"
        checkbox={<Checkbox {...accountForm.props('branchStatus')}></Checkbox>}
      />

      <ComboBox {...accountForm.props('workingStatus')} label="Firma Çalışma Durumu" defaultItems={accountWorkingStatus} itemKey="id" itemLabel="detail" />
      <ComboBox {...accountForm.props('accountStatus')} label="Firma Durumu" defaultItems={accountStatus} itemKey="id" itemLabel="detail" isRequired />

      <div className="col-span-2 grid grid-cols-2 gap-2 gap-x-8 grid-rows-[min-content_min-content] ">
        <DatePicker label="Kayıt Tarihi" {...accountForm.props('insertDate')} />
        <FileInput name="name" type="text" label="Firma Görseli" />

        <TextInput {...accountForm.props('accountComment')} className="row-span-2" type="text" label="Açıklama" textArea />

        <RadioGroup {...accountForm.props('accountBranchStatus')} orientation="vertical">
          <Radio value="1">Bilgi İsteyen</Radio>
          <Radio value="2">Araştırma İsteyen</Radio>
          <Radio value="3">Başvuru İsteyen</Radio>
          <Radio value="4">Eski Müşteriler</Radio>
          <Radio value="5">Başka Vekile Gidenler</Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-2">
        <Checkbox {...accountForm.props('paymentProblem')}>Ödeme'de sorun yaşanıyor?</Checkbox>
        <ComboBox {...accountForm.props('watchAuto')} label="Otomatik Gözlem" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
        <ComboBox
          {...accountForm.props('accountAgreement')}
          label="Sözleşmesi Var Mı?"
          defaultItems={accountAgreements}
          itemKey="id"
          itemLabel="detail"
          checkbox={<Checkbox {...accountForm.props('hasAgreement')}></Checkbox>}
        />
        <ComboBox {...accountForm.props('orderNumberRequired')} label="Sipariş Numarası Zorunlu Mu?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
        <ComboBox {...accountForm.props('accountPaymentType')} label="Ödeme Şekli" defaultItems={accountPaymentType} itemKey="id" itemLabel="detail" />
        <TextInput {...accountForm.props('renewalPerson')} type="text" label="Yenileme Bildirim İlgisi" />
      </div>

      <Accordion className="col-span-3" header="Firma İletişim Bilgileri" open>
        <div className="w-full grid grid-cols-3 gap-5 gap-x-8">
          <div className="flex flex-col min-h-48 gap-5">
            <TextInput {...accountForm.props('shippingAddressLine1')} type="text" label="Açık Adres" textArea />
            <TextInput {...accountForm.props('shippingAddressLine2')} type="text" label="Adres Kısım 2" textArea />
          </div>
          <div className="col-span-2 grid grid-cols-2  gap-5 gap-x-8 grid-rows-[min-content_min-content] ">
            <ComboBox {...accountForm.props('shippingCountry')} label="Ülke" defaultItems={country} itemKey="id" itemLabel="name" />
            <ComboBox {...accountForm.props('shippingCity')} label="Şehir / İl / Bölge" defaultItems={city} itemKey="id" itemLabel="cityName" />
            <ComboBox {...accountForm.props('shippingState')} label="Semt / İlçe" defaultItems={shippingStates} itemKey="id" itemLabel="cityName" />
            <TextInput {...accountForm.props('shippingZipCode')} type="text" label="Posta Kodu" />
          </div>
        </div>
      </Accordion>

      <Accordion className="col-span-3" header="İletişim Kişileri" open>
        <IletisimKisiler account={account} contactList={contactList} department={department} country={country} city={city} contactListLoading={props.contactListLoading} />
      </Accordion>
    </Form>
  );
};
