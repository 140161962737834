import * as API from 'api';
import { Button, Cell, Column, Paginator, Row, Table, TableHeader, TextInput } from 'components';
import { UserContext } from 'contexts';
import { useForm, usePageTableList } from 'hooks';
import { Save } from 'lucide-react';
import { useCallback, useContext, useEffect } from 'react';
import { Form, TableBody } from 'react-aria-components';
import { Account, AccountComment, AccountCommentForm, AccountCommentFormable } from 'types';
import { formatDate } from 'utils';

export interface AciklamalarProps {
  account?: Account;
}

export const Aciklamalar = (props: AciklamalarProps) => {
  const user = useContext(UserContext);

  const { account } = props;

  const [list, getList, loading] = API.ACCOUNT_COMMENT.useFetchByAccountId({});

  const form = useForm<AccountCommentFormable>(AccountCommentForm, { comment: '' });

  const tableList = usePageTableList({
    list,
    loading,
  });

  useEffect(() => {
    if (account) {
      getList(null, { accountId: account.id });
    }
  }, [account, getList]);

  const save = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      const accountComment: AccountComment = {
        ...value,
        account: { id: account?.id },
        employee: { id: user.employeeId },
        deleted: false,
      };

      await API.ACCOUNT_COMMENT.save(accountComment);
    } else {
      console.log(result.error);
      form.setAllTouched();
    }
  }, [form]);

  return (
    <div className="grid gap-2">
      <Form className="flex flex-col gap-2">
        <TextInput {...form.props('comment')} type="text" label="Açıklama (Genel)" textArea />
        <Button className="self-end" onPress={save}>
          <Save size={18} />
          Kaydet
        </Button>
      </Form>
      <div className="grid gap-1">
        <Table className="w-full" selectionMode="multiple">
          <TableHeader>
            <Column isRowHeader>No</Column>
            <Column>Tarih</Column>
            <Column>Ekleyen</Column>
            <Column>Açıklama</Column>
          </TableHeader>

          <TableBody {...tableList}>
            {(row) => (
              <Row key={row.id}>
                <Cell>{row.id}</Cell>
                <Cell>{formatDate(row.insertDate)}</Cell>
                <Cell>
                  {row.employee?.name} {row.employee?.surname}
                </Cell>
                <Cell>{row.comment}</Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        <Paginator {...tableList} />
      </div>
    </div>
  );
};
