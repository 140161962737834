import { Menu } from 'types';

export const menu: Menu = {
  title: 'root',
  path: 'root',
  children: [
    {
      title: 'Araştırma',
      path: 'arastirma',
    },
    {
      title: 'Dosya Yöneticisi',
      path: 'dosya-yoneticisi',
    },
    {
      title: 'Görev Yöneticisi',
      path: 'gorev-yoneticisi',
    },
    {
      title: 'Müşteri İlişkileri',
      path: 'musteri-iliskileri',
      children: [
        {
          title: 'Firma Kayit',
          path: '/firma-kayit',
        },
        {
          title: 'Firma Basit Arama',
          path: '/firma-basit-arama',
        },
        {
          title: 'Firma Evrak Arama',
          path: '/firma-evrak-basit-arama',
        },
        {
          title: 'Firma İletişim Arama',
          path: '/firma-iletisim-basit-arama',
        },
      ],
    },
    {
      title: 'Finans',
      path: 'finans',
    },
    {
      title: 'Y.D. Finans',
      path: 'yd-finans',
    },
    {
      title: 'Talep',
      path: 'talep',
    },
    {
      title: 'KPI',
      path: 'kpi',
    },
  ],
};

export const menuFlat = flatMenu(menu.children || []).flat();

function flatMenu(list: Menu[]): any[] {
  return list.map((menuItem) => {
    if (menuItem.children) {
      return flatMenu(menuItem.children)
        .map((item) => ({ ...item, flow: [menuItem, ...item.flow] }))
        .flat();
    }

    return { ...menuItem, flow: [{ ...menuItem, isLast: true }] };
  });
}
