import {
  Tab as AriaTab,
  TabList as AriaTabList,
  TabPanel as AriaTabPanel,
  Tabs as AriaTabs,
  TabListProps,
  TabPanelProps,
  TabProps as AriaTabProps,
  TabsProps,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

export interface TabProps extends AriaTabProps {
  pulse?: boolean;
}

let tabs = tv({
  base: 'w-full h-full overflow-hidden',
});

export const Tabs = (props: TabsProps) => {
  return <AriaTabs {...props} className={composeRenderProps(props.className, (className, renderProps) => tabs({ ...renderProps, className }))}></AriaTabs>;
};

let tabList = tv({
  base: 'flex flex-row border-b border-gray-400',
});

export const TabList = <T extends object>(props: TabListProps<T>) => {
  return <AriaTabList {...props} className={composeRenderProps(props.className, (className, renderProps) => tabList({ ...renderProps, className }))}></AriaTabList>;
};

let tab = tv({
  base: 'relative font-medium p-2 outline-none cursor-pointer border-b-3 transition duration-300 border-transparent hover:border-theme-200 selected:border-theme-400 selected:text-theme-400 disabled:text-gray-300 disabled:cursor-default ',
});

export const Tab = (props: TabProps) => {
  return (
    <AriaTab {...props} className={composeRenderProps(props.className, (className, renderProps) => tab({ ...renderProps, className }))}>
      {typeof props.children === 'function' ? props.children({} as any) : props.children}
      {props.pulse && (
        <span className="absolute flex h-2 w-2 right-0.5 top-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-2 w-2 bg-red-600"></span>
        </span>
      )}
    </AriaTab>
  );
};

let tabPanel = tv({
  base: 'h-full p-2 overflow-auto theme-scrollbar2',
});

export const TabPanel = (props: TabPanelProps) => {
  return <AriaTabPanel {...props} className={composeRenderProps(props.className, (className, renderProps) => tabPanel({ ...renderProps, className }))}></AriaTabPanel>;
};
