import * as API from 'api';
import { Button, Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { FolderSearch } from 'lucide-react';
import { useEffect } from 'react';
import { DialogTrigger, TableBody } from 'react-aria-components';
import { Account } from 'types';
import { formatDate } from 'utils';
import { EvraklarDosyaDialog } from './EvraklarDosya';

export interface EvraklarProps {
  account?: Account;
}

export const Evraklar = (props: EvraklarProps) => {
  const [list, getList, loading] = API.PAPERS.useFetchByAccountId({});

  useEffect(() => {
    if (props.account) {
      getList(null, { accountId: props.account.id });
    }
  }, [props.account, getList]);

  const tableList = usePageTableList({
    list,
    loading,
  });

  return (
    <div className="grid gap-1">
      <Table className="w-full" selectionMode="multiple">
        <TableHeader>
          <Column isRowHeader>No</Column>
          <Column>Evrak Tarihi</Column>
          <Column>Evrak Tarihi Bitimi</Column>
          <Column>Evrak Türü</Column>
          <Column>Kullanıcı</Column>
          <Column className="w-8"></Column>
        </TableHeader>

        <TableBody {...tableList}>
          {(row) => (
            <Row key={row.id}>
              <Cell>{row.index + 1}</Cell>
              <Cell>{formatDate(row.papersDate)}</Cell>
              <Cell>{formatDate(row.papersDueDate)}</Cell>
              <Cell>{row.papersType?.detail}</Cell>
              <Cell>{row.employee?.fullname}</Cell>
              <Cell>
                <DialogTrigger>
                  <Button variant="text">
                    <FolderSearch size={18} />
                  </Button>
                  <EvraklarDosyaDialog paper={row} />
                </DialogTrigger>
              </Cell>
            </Row>
          )}
        </TableBody>
      </Table>
      <Paginator {...tableList} />
    </div>
  );
};
