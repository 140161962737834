import * as API from 'api';
import { Accordion, Box, Button, Card, Cell, Column, ComboBox, DatePicker, Paginator, Row, SeachToolbar, Table, TableHeader } from 'components';
import { useForm, usePageTableList } from 'hooks';
import { useCallback } from 'react';
import { Form, TableBody } from 'react-aria-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PaperSearchForm, Papers, PapersSimpleSearchRequestModel } from 'types';
import { formatDate } from 'utils';

export const FirmaEvrakBasitArama = () => {
  const navigate = useNavigate();

  const form = useForm<PapersSimpleSearchRequestModel>(PaperSearchForm, {});

  const [list, getList, loading, clearList] = API.PAPERS.useFetchSimpleSearch({});

  const [accountGroup] = API.SOZLUK.ACCOUNT_GROUP.useFetchActive({ init: true });
  const [accountWorkingStatus] = API.SOZLUK.ACCOUNT_WORKING_STATUS.useFetchActive({ init: true });
  const [papersType] = API.SOZLUK.PAPERS_TYPE.useFetch({ init: true });

  const search = useCallback(() => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      getList(value);
    }
  }, [form, getList]);

  const tableList = usePageTableList({ list, loading });

  const onPressAccount = useCallback(
    (paper: Papers) => {
      navigate({
        pathname: '/firma-kayit',
        search: createSearchParams({
          firma: paper.account?.id + '',
        }).toString(),
      });
    },
    [navigate],
  );

  return (
    <Box className="grid grid-flow-row grid-rows-[46px_1fr] gap-2 overflow-hidden">
      <SeachToolbar onSearch={search} clearForm={form.reset} clearList={clearList} />
      <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
        <Accordion header="Arama Kriterleri" offset={16} open>
          <Card className="w-full">
            <Form className="grid w-full grid-cols-4 gap-5 gap-x-8" autoComplete="off">
              <DatePicker {...form.props('papersDateStart')} label="Evrak Tarihi Aralığı" />
              <DatePicker {...form.props('papersDateEnd')} label="Evrak Tarihi Aralığı Bitiş" labelClass="invisible" />

              <DatePicker {...form.props('papersDueDateStart')} label="Evrak Son Tarihi Aralığı" />
              <DatePicker {...form.props('papersDueDateEnd')} label="Evrak Son Tarihi Aralığı Bitiş" labelClass="invisible" />

              <ComboBox {...form.props('papersTypeId')} label="Evrak Türü" defaultItems={papersType} itemKey="id" itemLabel="detail" />
              <ComboBox {...form.props('accountGroup')} label="Şirket Grubu" defaultItems={accountGroup} itemKey="id" itemLabel="detail" />
              <ComboBox {...form.props('accountWorkingStatus')} label="Firma Çalışma Durumu" defaultItems={accountWorkingStatus} itemKey="id" itemLabel="detail" />
            </Form>
          </Card>
        </Accordion>

        <Card>
          <div className="grid gap-1">
            <Table {...tableList}>
              <TableHeader>
                <Column isRowHeader>No</Column>
                <Column>Firma Kod</Column>
                <Column>Firma Ünvan</Column>
                <Column>Firma Çalışma Durumu</Column>
                <Column>Şirket Grubu</Column>
                <Column>Evrak Tarihi</Column>
                <Column>Evrak Bitim Tarihi</Column>
                <Column>Evrak Türü</Column>
                <Column>Kullanıcı</Column>
              </TableHeader>

              <TableBody {...tableList}>
                {(row) => (
                  <Row key={row.id}>
                    <Cell>{row.index}</Cell>
                    <Cell>
                      <Button variant="text" className="text-theme-600 w-full" onPress={() => onPressAccount(row)}>
                        {row.account?.code}
                      </Button>
                    </Cell>
                    <Cell>{row.account?.title}</Cell>
                    <Cell>{row.account?.workingStatus?.detail}</Cell>
                    <Cell>{row.account?.accountGroup?.detail}</Cell>
                    <Cell>{formatDate(row.papersDate)}</Cell>
                    <Cell>{formatDate(row.papersDueDate)}</Cell>
                    <Cell>{row.papersType?.detail}</Cell>
                    <Cell>{row.employee?.fullname}</Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
            <Paginator {...tableList} />
          </div>
        </Card>
      </div>
    </Box>
  );
};

FirmaEvrakBasitArama.path = '/firma-evrak-basit-arama';
FirmaEvrakBasitArama.title = 'Firma Evrak Arama';
