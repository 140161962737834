import {
  Menu as AriaMenu,
  TabList as AriaTabList,
  MenuItem as AriaMenuItem,
  Tabs as AriaTabs,
  MenuItemProps,
  MenuProps,
  TabListProps,
  TabPanelProps,
  TabProps,
  TabsProps,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Popover } from './Popover';
import { Check } from 'lucide-react';

let menu = tv({
  base: 'p-1 outline outline-0 overflow-auto',
});

export const Menu = <T extends object>(props: MenuProps<T>) => {
  return (
    <Popover>
      <AriaMenu {...props} className={menu({ className: props.className })}></AriaMenu>
    </Popover>
  );
};

let menuItem = tv({
  base: 'group flex items-center gap-4 cursor-pointer select-none py-2 pl-3 pr-1 rounded-sm outline-0 text-sm transition-colors duration-100',
  variants: {
    isDisabled: {
      false: 'text-gray-900',
      true: 'text-gray-300'
    },
    isFocused: {
      true: 'bg-theme-400 text-white'
    }
  }
});

export const MenuItem = (props: MenuItemProps) => {
  return (
    <AriaMenuItem {...props} className={menuItem}>
      {composeRenderProps(props.children, (children, {selectionMode, isSelected}) => <>
        {selectionMode !== 'none' && (
          <span className="w-4 flex items-center">
            {isSelected && <Check aria-hidden className="w-4 h-4" />}
          </span>
        )}
        <span className="flex-1 flex items-center gap-2 truncate font-normal group-selected:font-semibold">
          {children}
        </span>
      </>)}
    </AriaMenuItem>
  );
};
