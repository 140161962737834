import { toIdList } from 'utils';
import { BillingAddress, ShippingAddress } from './Adress';
import { Employee } from './Employee';
import {
  AccountAgreement,
  AccountCategory,
  AccountGroup,
  AccountIndustry,
  AccountInformedBy,
  AccountOwner,
  AccountPaymentType,
  AccountStatus,
  AccountTurnover,
  AccountType,
  AccountWorkingStatus,
  Currency,
  CustomerRepresentative,
  Department,
  EmployeeNumber,
  TaxOffice,
} from './sozluk';

import { z } from 'zod';

export type Account = Partial<{
  id: number;
  code: string;
  title: string;
  branchStatus: boolean;
  branchAccount: Account;
  participateStatus: boolean;
  participateAccount: Account;
  workingRepresentativeStatus: boolean;
  workingRepresentative: Account;
  employeeNumber: EmployeeNumber;
  accountOwner: AccountOwner;
  informedBy: AccountInformedBy;
  billingAddress: BillingAddress;
  shippingAddress: ShippingAddress;
  // List<ChoosingReasons> choosingReasons: number;
  customerRepresentative: CustomerRepresentative[];
  departmentsConcerned: Department[];
  accountComment: string;
  hasPriceList: boolean;
  priceDiscount: boolean;
  industries: AccountIndustry[];
  // AccountVisual accountVisual: number;
  representative: boolean;
  deleted: boolean;
  inEmailList: boolean;
  logoNumber: string;
  accountType: AccountType;
  accountCategory: AccountCategory;
  taxOffice: TaxOffice;
  taxpayerId: string;
  TCIdentityNumber: string;
  tpeownerNumber: string;
  workingStatus: AccountWorkingStatus;
  accountStatus: AccountStatus;
  accountTurnover: AccountTurnover;
  accountTurnoverCurrency: Currency;
  accountInternetAddress: string;
  accountEmail: string;
  insertDate: string;
  version: string;
  accountGroup: AccountGroup;
  oldTitle: string;
  wasPotential: boolean;
  faxNumber: string;
  telNumber: string;
  accountBranchStatus: string;
  paymentProblem: boolean;
  // List<AccountComment> accountComments: number;
  hasAgreement: boolean;
  accountAgreement: AccountAgreement;
  watchAuto: boolean;

  dept1Responsible: Employee[];
  dept2Responsible: Employee[];
  dept3Responsible: Employee[];
  dept4Responsible: Employee[];
  dept5Responsible: Employee[];
  dept6Responsible: Employee[];
  dept7Responsible: Employee[];
  dept9Responsible: Employee[];
  dept10Responsible: Employee[];
  dept11Responsible: Employee[];
  dept12Responsible: Employee[];
  dept14Responsible: Employee[];
  dept15Responsible: Employee[];
  dept17Responsible: Employee[];
  dept19Responsible: Employee[];
  dept20Responsible: Employee[];
  dept21Responsible: Employee[];
  dept24Responsible: Employee[];
  dept25Responsible: Employee[];
  dept26Responsible: Employee[];
  dept30Responsible: Employee[];
  dept32Responsible: Employee[];
  dept33Responsible: Employee[];
  dept37Responsible: Employee[];
  dept38Responsible: Employee[];
  dept39Responsible: Employee[];
  dept41Responsible: Employee[];

  createdBy: Employee;
  accountPaymentType: AccountPaymentType;
  bulkTransactionCost: number;
  bulkTransactionDay: number;
  renewalPerson: string;
  orderNumberRequired: boolean;
  maxDayForTransaction: number;
}>;

export type AccountFormable = Partial<{
  code: string;
  tpeownerNumber: string;
  title: string;
  oldTitle: string;
  accountOwner: number;
  accountGroup: number;
  accountType: number;
  representative: boolean;
  workingRepresentativeStatus: boolean;
  workingRepresentative: number;
  branchStatus: boolean;
  branchAccount: number;
  workingStatus: number;
  accountStatus: number;
  insertDate: string;
  accountVisual: string;
  accountBranchStatus: string;
  accountComment: string;
  paymentProblem: boolean;
  watchAuto: boolean;
  hasAgreement: boolean;
  accountAgreement: number;
  orderNumberRequired: boolean;
  accountPaymentType: number;
  renewalPerson: string;

  shippingAddressLine1: string;
  shippingAddressLine2: string;
  shippingCountry: number;
  shippingCity: number;
  shippingState: number;
  shippingZipCode: string;

  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCountry: number;
  billingCity: number;
  billingState: number;
  billingZipCode: string;

  accountCategory: number;
  employeeNumber: number;
  industries: number[];
  accountTurnover: number;
  accountTurnoverCurrency: number;
  bulkTransactionCost: number;
  bulkTransactionDay: number;
  maxDayForTransaction: number;
  hasPriceList: boolean;
  priceDiscount: boolean;
  inEmailList: boolean;
  accountInternetAddress: string;
  accountEmail: string;
  faxNumber: string;
  departmentsConcerned: number[];
  customerRepresentative: number[];
  telNumber: string;
  informedBy: number;

  dept1Responsible: number[];
  dept2Responsible: number[];
  dept3Responsible: number[];
  dept4Responsible: number[];
  dept5Responsible: number[];
  dept6Responsible: number[];
  dept7Responsible: number[];
  dept9Responsible: number[];
  dept10Responsible: number[];
  dept11Responsible: number[];
  dept12Responsible: number[];
  dept14Responsible: number[];
  dept15Responsible: number[];
  dept17Responsible: number[];
  dept19Responsible: number[];
  dept20Responsible: number[];
  dept21Responsible: number[];
  dept24Responsible: number[];
  dept25Responsible: number[];
  dept26Responsible: number[];
  dept30Responsible: number[];
  dept32Responsible: number[];
  dept33Responsible: number[];
  dept37Responsible: number[];
  dept38Responsible: number[];
  dept39Responsible: number[];
  dept41Responsible: number[];
}>;

export type AccountSearchFormable = Partial<{
  accountCategory: number;
  accountCode: string;
  accountEmail: string;
  accountGroup: number;
  accountPaymentType: number;
  accountStatus: number;
  accountType: number;
  accountWorkingStatus: number;
  city: number;
  contactName: string;
  contactSurname: string;
  country: number;
  deleted: boolean;
  industryIdList: number[];
  representative: boolean;
  state: number;
  tpeOwnerNumber: string;
  watchAuto: boolean;
  workingRepresentativeCode: string;
}>;

export const AccountSearchForm = z.object({
  accountCategory: z.number().optional().nullable(),
  accountCode: z.string().optional().nullable(),
  accountEmail:z.string().optional().nullable(),
  accountGroup: z.number().optional().nullable(),
  accountPaymentType:z.number().optional().nullable(),
  accountStatus:z.number().optional().nullable(),
  accountType: z.number().optional().nullable(),
  accountWorkingStatus: z.number().optional().nullable(),
  city: z.number().optional().nullable(),
  contactName: z.string().optional().nullable(),
  contactSurname: z.string().optional().nullable(),
  country:z.number().optional().nullable(),
  deleted: z.boolean().optional().nullable(),
  industryIdList: z.array(z.number()).optional().nullable(),
  representative:z.boolean().optional().nullable(),
  state: z.boolean().optional().nullable(),
  tpeOwnerNumber: z.string().optional().nullable(),
  watchAuto: z.boolean().optional().nullable(),
  workingRepresentativeCode: z.string().optional().nullable(),
});

export const AccountForm = z.object({
  code: z.string().optional().nullable(),
  tpeownerNumber: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  oldTitle: z.string().optional().nullable(),
  accountOwner: z.number().optional().nullable(),
  accountGroup: z.number().optional().nullable(),
  accountType: z.number().optional().nullable(),
  representative: z.boolean().optional().nullable(),
  workingRepresentativeStatus: z.boolean().optional().nullable(),
  workingRepresentative: z.number().optional().nullable(),
  branchAccount: z.number().optional().nullable(),
  workingStatus: z.number().optional().nullable(),
  accountStatus: z.number().optional().nullable(),
  insertDate: z.string().optional().nullable(),
  accountVisual: z.string().optional().nullable(),
  branchStatus: z.boolean().optional().nullable(),
  accountBranchStatus: z.string().optional().nullable(),
  accountComment: z.string().optional().nullable(),
  paymentProblem: z.boolean().optional().nullable(),
  watchAuto: z.boolean().optional().nullable(),
  hasAgreement: z.boolean().optional().nullable(),
  accountAgreement: z.number().optional().nullable(),
  orderNumberRequired: z.boolean().optional().nullable(),
  accountPaymentType: z.number().optional().nullable(),
  renewalPerson: z.string().optional().nullable(),

  shippingAddressLine1: z.string().optional().nullable(),
  shippingAddressLine2: z.string().optional().nullable(),
  shippingCountry: z.number().optional().nullable(),
  shippingCity: z.number().optional().nullable(),
  shippingState: z.number().optional().nullable(),
  shippingZipCode: z.string().optional().nullable(),

  billingAddressLine1: z.string().optional().nullable(),
  billingAddressLine2: z.string().optional().nullable(),
  billingCountry: z.number().optional().nullable(),
  billingCity: z.number().optional().nullable(),
  billingState: z.number().optional().nullable(),
  billingZipCode: z.string().optional().nullable(),

  accountCategory: z.number().optional().nullable(),
  employeeNumber: z.number().optional().nullable(),
  industries: z.array(z.number()).optional().nullable(),
  accountTurnover: z.number().optional().nullable(),
  accountTurnoverCurrency: z.number().optional().nullable(),
  bulkTransactionCost: z.number().optional().nullable(),
  bulkTransactionDay: z.number().optional().nullable(),
  maxDayForTransaction: z.number().optional().nullable(),
  hasPriceList: z.boolean().optional().nullable(),
  priceDiscount: z.boolean().optional().nullable(),
  inEmailList: z.boolean().optional().nullable(),
  accountInternetAddress: z.string().optional().nullable(),
  accountEmail: z.string().optional().nullable(),
  faxNumber: z.string().optional().nullable(),
  departmentsConcerned: z.array(z.number()).optional().nullable(),
  customerRepresentative: z.array(z.number()).optional().nullable(),
  telNumber: z.string().optional().nullable(),
  informedBy: z.number().optional().nullable(),

  dept1Responsible: z.array(z.number()).optional().nullable(),
  dept2Responsible: z.array(z.number()).optional().nullable(),
  dept3Responsible: z.array(z.number()).optional().nullable(),
  dept4Responsible: z.array(z.number()).optional().nullable(),
  dept5Responsible: z.array(z.number()).optional().nullable(),
  dept6Responsible: z.array(z.number()).optional().nullable(),
  dept7Responsible: z.array(z.number()).optional().nullable(),
  dept9Responsible: z.array(z.number()).optional().nullable(),
  dept10Responsible: z.array(z.number()).optional().nullable(),
  dept11Responsible: z.array(z.number()).optional().nullable(),
  dept12Responsible: z.array(z.number()).optional().nullable(),
  dept14Responsible: z.array(z.number()).optional().nullable(),
  dept15Responsible: z.array(z.number()).optional().nullable(),
  dept17Responsible: z.array(z.number()).optional().nullable(),
  dept19Responsible: z.array(z.number()).optional().nullable(),
  dept20Responsible: z.array(z.number()).optional().nullable(),
  dept21Responsible: z.array(z.number()).optional().nullable(),
  dept24Responsible: z.array(z.number()).optional().nullable(),
  dept25Responsible: z.array(z.number()).optional().nullable(),
  dept26Responsible: z.array(z.number()).optional().nullable(),
  dept30Responsible: z.array(z.number()).optional().nullable(),
  dept32Responsible: z.array(z.number()).optional().nullable(),
  dept33Responsible: z.array(z.number()).optional().nullable(),
  dept37Responsible: z.array(z.number()).optional().nullable(),
  dept38Responsible: z.array(z.number()).optional().nullable(),
  dept39Responsible: z.array(z.number()).optional().nullable(),
  dept41Responsible: z.array(z.number()).optional().nullable(),
});

export const accountToForm = (account?: Account) => {
  if (!account) {
    return {} as AccountFormable;
  }

  const value: AccountFormable = {
    ...account,
    accountOwner: account.accountOwner?.id,
    accountGroup: account.accountGroup?.id,
    accountType: account.accountType?.id,
    workingRepresentative: account.workingRepresentative?.id,
    branchAccount: account.branchAccount?.id,
    workingStatus: account.workingStatus?.id,
    accountStatus: account.accountStatus?.id,
    accountVisual: '',
    accountAgreement: account.accountAgreement?.id,
    accountPaymentType: account.accountPaymentType?.id,

    shippingAddressLine1: account.shippingAddress?.addressLine1,
    shippingAddressLine2: account.shippingAddress?.addressLine2,
    shippingCountry: account.shippingAddress?.country?.id,
    shippingCity: account.shippingAddress?.city?.id,
    shippingState: account.shippingAddress?.state?.id,
    shippingZipCode: account.shippingAddress?.zipCode,

    billingAddressLine1: account.billingAddress?.addressLine1,
    billingAddressLine2: account.billingAddress?.addressLine2,
    billingCountry: account.billingAddress?.country?.id,
    billingCity: account.billingAddress?.city?.id,
    billingState: account.billingAddress?.state?.id,
    billingZipCode: account.billingAddress?.zipCode,

    accountCategory: account.accountCategory?.id,
    employeeNumber: account.employeeNumber?.id,
    industries: toIdList(account.industries),
    accountTurnover: account.accountTurnover?.id,
    accountTurnoverCurrency: account.accountTurnoverCurrency?.id,
    departmentsConcerned: [],
    customerRepresentative: [],
    informedBy: account.informedBy?.id,

    dept1Responsible: toIdList(account.dept1Responsible),
    dept2Responsible: toIdList(account.dept2Responsible),
    dept3Responsible: toIdList(account.dept3Responsible),
    dept4Responsible: toIdList(account.dept4Responsible),
    dept5Responsible: toIdList(account.dept5Responsible),
    dept6Responsible: toIdList(account.dept6Responsible),
    dept7Responsible: toIdList(account.dept7Responsible),
    dept9Responsible: toIdList(account.dept9Responsible),
    dept10Responsible: toIdList(account.dept10Responsible),
    dept11Responsible: toIdList(account.dept11Responsible),
    dept12Responsible: toIdList(account.dept12Responsible),
    dept14Responsible: toIdList(account.dept14Responsible),
    dept15Responsible: toIdList(account.dept15Responsible),
    dept17Responsible: toIdList(account.dept17Responsible),
    dept19Responsible: toIdList(account.dept19Responsible),
    dept20Responsible: toIdList(account.dept20Responsible),
    dept21Responsible: toIdList(account.dept21Responsible),
    dept24Responsible: toIdList(account.dept24Responsible),
    dept25Responsible: toIdList(account.dept25Responsible),
    dept26Responsible: toIdList(account.dept26Responsible),
    dept30Responsible: toIdList(account.dept30Responsible),
    dept32Responsible: toIdList(account.dept32Responsible),
    dept33Responsible: toIdList(account.dept33Responsible),
    dept37Responsible: toIdList(account.dept37Responsible),
    dept38Responsible: toIdList(account.dept38Responsible),
    dept39Responsible: toIdList(account.dept39Responsible),
    dept41Responsible: toIdList(account.dept41Responsible),
  };

  return value;
};
