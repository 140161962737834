import { CalendarDate, CalendarDateTime } from '@internationalized/date';
import { Account } from './Account';
import { ContactAddress } from './Adress';
import { ContactDepartmentConcerned, ContactTitle, ContactType } from './sozluk';
import { z } from 'zod';

export type ContactInformation = Partial<{
  id: number;
  contactType: ContactType;
  contactTypeDetail: string;
  assignedContactType: boolean;
  customerVisible: boolean;
  extensionNumber: string;
}>;

export type Contact = Partial<{
  id: number;
  contactName: string;
  contactSurname: string;
  contactTitle: ContactTitle;
  contactAddress: ContactAddress;
  contactComment: string;
  contactInformation: ContactInformation[];
  contactAccount: Account;
  contactSection: string;
  contactJob: string;
  contactisInEmailList: boolean;
  contactisRelevantPerson: boolean;
  contactisInSmsList: boolean;
  contactisInPromotionList: boolean;
  contactBirthDay: string;
  insertDate: string;
  deleted: boolean;
  version: string;
  departmentsConcerned: ContactDepartmentConcerned[];
}>;

export type ContactInformationFormable = Partial<{
  contactType: number;
  contactTypeDetail: string;
  extensionNumber: string;
}>;

export type ContactFormable = Partial<{
  contactName: string;
  contactSurname: string;
  contactTitle: number;
  contactBirthDay: CalendarDate;
  contactSection: string;
  contactJob: string;
  contactisRelevantPerson: boolean;
  contactisInPromotionList: boolean;
  contactComment: string;
  departmentsConcerned: number[];
  contactisInEmailList: boolean;
  contactisInSmsList: boolean;

  contactAddressLine1: string;
  contactAddressLine2: string;
  contactCountry: number;
  contactCity: number;
  contactState: number;
  contactZipCode: string;
}>;

export type ContactSimpleSearchRequestModel = {
  accountId: number;
  contactName: string;
  contactSurname: string;
  relevantPerson: boolean;
  contactDetail: string;
  extensionNumber: string;
};

export const ContactForm = z.object({
  contactName: z.string().nullish(),
  contactSurname: z.string().nullish(),
  contactTitle: z.number().nullish(),
  contactBirthDay: z.custom((val: unknown) => Boolean(val), 'Zorunludur').nullish(),
  contactSection: z.string().nullish(),
  contactJob: z.string().nullish(),
  contactisRelevantPerson: z.boolean().nullish(),
  contactisInPromotionList: z.boolean().nullish(),
  contactComment: z.string().nullish(),
  departmentsConcerned: z.array(z.number()).nullish(),
  contactisInEmailList: z.boolean().nullish(),
  contactisInSmsList: z.boolean().nullish(),

  contactAddressLine1: z.string().nullish(),
  contactAddressLine2: z.string().nullish(),
  contactCountry: z.number().nullish(),
  contactCity: z.number().nullish(),
  contactState: z.number().nullish(),
  contactZipCode: z.string().nullish(),
});

export const ContactInformationForm = z.object({
  contactType: z.number(),
  contactTypeDetail: z.string(),
  extensionNumber: z.string().nullish(),
});

export const ContactSearchForm = z.object({
  accountId: z.number().nullish(),
  contactName: z.string().nullish(),
  contactSurname: z.string().nullish(),
  relevantPerson: z.boolean().nullish(),
  contactDetail: z.string().nullish(),
  extensionNumber: z.string().nullish(),
});
