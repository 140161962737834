import { useFetch } from 'hooks';
import { AccountComment, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<AccountComment[]>('/account-comment/get-active-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (accountComment: AccountComment) => request('/account-comment/save', 'POST', accountComment),
  delete: async (accountComment: AccountComment) => request('/account-comment/delete', 'POST', null, { id: accountComment.id }),
};
