import { useFetch } from 'hooks';
import { Task, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<Task[]>('/task/get-task-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<Task[]>('/task/get-task-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (task: Task) => request('/task/save', 'POST', task),
  delete: async (task: Task) => request('/task/delete', 'POST', null, { id: task.id }),
};
