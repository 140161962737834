//http://172.20.10.3:8080/

import { auth } from "contexts";

 const BASE_URL = '/pati-be';
//const BASE_URL = 'http://195.85.216.67:80/pati-be';
//const BASE_URL = 'http://172.20.10.3:8080';

const defaultOptions: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const request = async (url: string, method = 'GET', data: any = null, params: any = null, options: RequestInit = defaultOptions, noMessage = false) => {
  localStorage.setItem('pati.atlassoft.last', new Date().getTime() + '');
  
  const searchParams = new URLSearchParams(params).toString();
  const urlTail = params ? `?${searchParams}` : '';

  const fullUrl = `${BASE_URL}${url}${urlTail}`;

  options = { ...defaultOptions, ...options };

  const headers = options?.headers ? new Headers(options.headers) : new Headers();

  if (!headers.has('Authorization')) {
    headers.set('Authorization', 'Basic ' + auth.basic);
  }

  let body: any = null;
  if (data instanceof FormData) {
    body = data;
    headers.delete('Content-Type');
  } else {
    headers.set('Content-Type',  'application/json');
    body = data ? JSON.stringify(data) : null;
  }
  
  options.headers = headers;

  try {
    const response = await fetch(fullUrl, {
      ...options,
      method,
      body,
    });

    const responseData = await response.json();
    //message.destroy()
    if (responseData.sonuc) {
      //!noMessage && message.success(responseData.message);
    } else if (responseData.sonuc === false) {
      //message.error(responseData.message);
    }

    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      data: responseData?.data,
      error: response.ok ? null : responseData,
    };
  } catch (error) {
    console.error('Request Error : ', fullUrl, '\n', error);

    return {
      ok: false,
      error: error,
    };
  }
};
