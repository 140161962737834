import range from 'just-range';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { HTMLAttributes, useMemo } from 'react';
import { Button } from './Button';

export interface PaginatorProps extends HTMLAttributes<HTMLElement> {
  items: unknown[];
  pageCount: number;
  pageIndex: number;
  pageNumber: number;
  prePage: () => void;
  nextPage: () => void;
  firstPage: () => void;
  lastPage: () => void;
  setPage: (pageIndex: number) => void;
}

export const Paginator = (props: PaginatorProps) => {
  const leftPages = useMemo(() => {
    let offset = 2;
    if (props.pageNumber === props.pageCount) {
      offset = 4;
    } else if (props.pageNumber === props.pageCount - 1) {
      offset = 3;
    }
    return range(Math.max(props.pageNumber - offset, 1), props.pageNumber);
  }, [props.pageNumber, props.pageCount]);

  const rightPages = useMemo(() => {
    let offset = 3;
    if (props.pageNumber === 1) {
      offset = 5;
    } else if (props.pageNumber === 2) {
      offset = 4;
    }
    return range(props.pageNumber + 1, Math.min(props.pageNumber + offset, props.pageCount + 1));
  }, [props.pageNumber, props.pageCount]);

  return (
    <div className="bg-white flex flex-row items-center justify-center gap-2 p-1 border border-gray-300 rounded">
      <Button variant="secondary" className="text-slate-600" onPress={props.firstPage}>
        <ChevronsLeft size={18} />
      </Button>
      <Button variant="secondary" className="text-slate-600" onPress={props.prePage}>
        <ChevronLeft size={18} />
      </Button>
      <div className="flex flex-row gap-1">
        {leftPages.map((page) => (
          <Button variant="text" className="w-11" key={page} onPress={() => props.setPage(page - 1)}>
            {page}
          </Button>
        ))}
        <Button variant="text" className="text-theme-400 w-11">
          {props.pageNumber}
        </Button>
        {rightPages.map((page) => (
          <Button variant="text" className="w-11" key={page} onPress={() => props.setPage(page - 1)}>
            {page}
          </Button>
        ))}
      </div>
      <Button variant="secondary" className="text-slate-600" onPress={props.nextPage}>
        <ChevronRight size={18} />
      </Button>
      <Button variant="secondary" className="text-slate-600" onPress={props.lastPage}>
        <ChevronsRight size={18} />
      </Button>
    </div>
  );
};
