import { ChevronDown, XCircle } from 'lucide-react';
import React, { useCallback } from 'react';
import {
  Button,
  Collection,
  ComboBoxStateContext,
  FieldError,
  Group,
  Header,
  Input,
  Key,
  Label,
  ListBox,
  ListBoxItem,
  ComboBox as RACComboBox,
  ComboBoxProps as RACComboBoxProps,
  Section,
  Text,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Popover } from './Popover';

export interface ComboBoxProps<T extends object> extends RACComboBoxProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  section?: boolean;
  itemKey: keyof T;
  itemLabel: keyof T;
  childKey?: keyof T;
  checkbox?: JSX.Element;
  value?: Key | null | undefined;
  onChange?: (key: Key) => any;
}

let textInput = tv({
  slots: {
    base: 'relative flex flex-col items-start gap-0.5',
    label: 'flex gap-2 items-center font-semibold  pl-0.5',
    group:
      'relative w-full flex items-center bg-white border border-slate-300 rounded pr-1 outline-none focus-within:outline-2 focus-within:outline-theme-300 focus-within:outline-offset-1 ',
    input: 'w-full flex-1 bg-transparent outline-0 p-1.5 px-2 pr-4',
    description: 'text-xs pl-0.5',
    error: 'absolute text-xs pl-0.5 text-red-600 -bottom-4',
    list: 'flex flex-col max-h-60 overflow-auto',
    listItem:
      'px-2 py-1 cursor-default hover:bg-theme-100 focus-visible:bg-theme-100 selected:bg-theme-200 selected:text-theme-600  selected:focus-visible:bg-theme-300 transition-colors duration-300',
    sectionItem: 'px-2 py-1 font-bold cursor-default',
  },
  variants: {
    isInvalid: {
      true: {
        group: 'border-red-600',
      },
    },
    isDisabled: {
      true: {
        label: 'text-slate-300',
        group: 'bg-slate-100 border-slate-200',
      },
    },
    isReadOnly: {
      true: {
        group: 'bg-slate-50 border-slate-200',
      },
    },
  },
});

export const ComboBox = <T extends object>(props: ComboBoxProps<T>) => {
  const { base, label, group, input, description, error, list, listItem, sectionItem } = textInput({
    isInvalid: props.isInvalid,
    isDisabled: props.isDisabled,
    isReadOnly: props.isReadOnly,
  });

  const _onChange = useCallback(
    (key: Key) => {
      if (props.onChange) {
        props.onChange(key);
      }
      if (props.onSelectionChange) {
        props.onSelectionChange(key);
      }
    },
    [props.onChange],
  );

  return (
    <RACComboBox
      {...props}
      selectedKey={props.value as any}
      onSelectionChange={_onChange}
      shouldFocusWrap
      validationBehavior="aria"
      menuTrigger="focus"
      className={composeRenderProps(props.className, (className, renderProps) => base({ ...renderProps, className }))}
    >
      <Label className={label()}>
        <span className="flex-1">
          {props.label} {props.isRequired && <span className="text-red-600">*</span>}
        </span>
        {props.checkbox && props.checkbox}
      </Label>
      <Group className={group()}>
        <Button className="flex-1">
          <Input className={input()} role="presentation" autoComplete="off" placeholder="Seçiniz..." />
        </Button>
        {Boolean(props.value) && props.value !== 'not_a_value' && <ComboBoxClearButton />}
        <Button>
          <ChevronDown />
        </Button>
      </Group>
      {props.description && (
        <Text className={description()} slot="description">
          {props.description}
        </Text>
      )}
      <FieldError className={error()}>{props.errorMessage}</FieldError>
      <Popover>
        <ListBox className={list()}>
          {props.section
            ? (section: T) => (
                <Section id={section[props.itemKey] as any}>
                  <Header className={sectionItem()}>{section[props.itemLabel] + ''}</Header>
                  <Collection items={props.childKey ? (section[props.childKey] as Iterable<T>) : []}>
                    {(item) => (
                      <ListBoxItem className={listItem({ className: 'px-5' })} id={item[props.itemKey] as any}>
                        {item[props.itemLabel] + ''}
                      </ListBoxItem>
                    )}
                  </Collection>
                </Section>
              )
            : (item: T) => (
                <ListBoxItem className={listItem()} id={item[props.itemKey] as any}>
                  {item[props.itemLabel] + ''}
                </ListBoxItem>
              )}
        </ListBox>
      </Popover>
    </RACComboBox>
  );
};

const ComboBoxClearButton = () => {
  let state = React.useContext(ComboBoxStateContext);

  return (
    <Button slot={null} className="outline-none absolute right-[26px] " aria-label="Clear" onPress={() => state?.setSelectedKey(null)}>
      <XCircle className="stroke-slate-500" size={20} />
    </Button>
  );
};
