import useResizeObserver from '@react-hook/resize-observer';
import { ChevronDown } from 'lucide-react';
import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { tv } from 'tailwind-variants';
import { Button } from './Button';

export interface AccordionProps extends HTMLAttributes<HTMLElement> {
  header: string;
  panelClass?: string;
  open?: boolean;
  offset?: number;
}

const panelContainer = tv({
  base: 'overflow-hidden transition-all',
  variants: {
    open: {
      true: '',
    },
  },
});

const panel = tv({
  base: 'p-4 pb-0',
});

const chevron = tv({
  base: 'transition-all -rotate-90 ',
  variants: {
    open: {
      true: 'rotate-0 ',
    },
  },
});

export const Accordion = (props: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(Boolean(props.open));
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [scrollHeight, setScrollHeight] = useState<number>(element?.scrollHeight || 0);

  const [childElement, setChildElement] = useState<HTMLElement | null>(null);

  const _offset = props.offset ?? 32;

  useResizeObserver(childElement, () => {
    console.log('LL');
    setScrollHeight(childElement?.scrollHeight || 0);
  });

  const onPress = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const height = useMemo(() => {
    if (open) {
      return scrollHeight + _offset;
    }

    return 0;
  }, [element, scrollHeight, open, _offset]);

  return (
    <div {...props}>
      <Button
        onPress={onPress}
        variant="secondary"
        className="w-full justify-start text-base font-semibold px-2 py-1.5 shadow-sm border border-slate-400/60 hover:border-slate-400"
      >
        <ChevronDown className={chevron({ open })} />
        <span>{props.header}</span>
      </Button>
      <div ref={setElement} className={panelContainer({ open })} style={{ height }}>
        <div ref={setChildElement} className={panel({ className: props.panelClass })}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
