import { useFetch } from 'hooks';
import { FeedBack, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<FeedBack[]>('/feed-back/get-feed-back-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<FeedBack[]>('/feed-back/get-active-feed-back-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (feedBack: FeedBack) => request('/feed-back/save', 'POST', feedBack),
  delete: async (feedBack: FeedBack) => request('/feed-back/delete', 'POST', null, { id: feedBack.id }),
};
