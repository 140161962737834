import * as API from 'api';
import { Accordion, Button, Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { BadgePlus } from 'lucide-react';
import { useEffect } from 'react';
import { DialogTrigger, TableBody } from 'react-aria-components';
import { Account, Contact, Department, Employee } from 'types';
import { TeklifKayitDialog } from './TeklifKayit';

export interface TekliflerProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const Teklifler = (props: TekliflerProps) => {
  const [list, getList, loading] = API.PROPOSAL.useFetchByAccountId({});

  useEffect(() => {
    if (props.account) {
      getList(null, { accountId: props.account.id });
    }
  }, [props.account, getList]);

  const tableList = usePageTableList({ list, loading });

  return (
    <Accordion header="Teklifler" open>
      <div className="mb-2">
        <DialogTrigger>
          <Button variant="secondary">
            <BadgePlus size={18} />
            Ekle
          </Button>
          <TeklifKayitDialog {...props} />
        </DialogTrigger>
      </div>
      <div className="grid gap-1">
        <Table {...tableList}>
          <TableHeader>
            <Column isRowHeader>No</Column>
            <Column>Referans No</Column>
            <Column>Teklif Durumu</Column>
            <Column>Son Tarih</Column>
          </TableHeader>

          <TableBody {...tableList}>
            {(row) => (
              <Row key={row.id}>
                <Cell>{row.id}</Cell>
                <Cell>{row.proposalReferanceNumber}</Cell>
                <Cell>{row.proposalStatus}</Cell>
                <Cell>{row.proposalDateOfValidity}</Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        <Paginator {...tableList} />
      </div>
    </Accordion>
  );
};
