import * as API from 'api';
import { Accordion, Button, Cell, Column, ComboBox, DatePicker, Dialog, Modal, ModalHeader, Row, SelectListBox, Table, TableHeader, TextInput } from 'components';
import { evetHayir } from 'constants';
import { useForm } from 'hooks';
import { BadgePlus } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Form, Heading, TableBody } from 'react-aria-components';
import {
  Account,
  City,
  Contact,
  ContactDepartmentConcerned,
  ContactForm,
  ContactFormable,
  ContactInformation,
  ContactInformationForm,
  ContactInformationFormable,
  ContactTitle,
  ContactType,
  Country,
  Department,
  adressFormToModel,
} from 'types';
import { parseDateSafe, toIdList, toModelList } from 'utils';
import { z } from 'zod';

export interface IletisimKayitProps {
  contact?: Contact;
  account?: Account;
  country?: Country[];
  city?: City[];
  department?: Department[];
  contactTitle?: ContactTitle[];
  contactType?: ContactType[];
  isUpdate?: boolean;
}

export const IletisimKayitDialog = (props: IletisimKayitProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh]">
        <ModalHeader header="İletişim Kayıt" />
        <IletisimKayit {...props}></IletisimKayit>
      </Dialog>
    </Modal>
  );
};

export const IletisimKayit = ({ contact, account, country, city, department, contactTitle, contactType, isUpdate = false }: IletisimKayitProps) => {
  const contactForm = useForm<ContactFormable>(ContactForm, {});
  const contactInformationForm = useForm<ContactInformationFormable>(ContactInformationForm, {});

  const [informationList, setInformationList] = useState<ContactInformation[]>([]);

  const [states, getStates] = API.SOZLUK.CITY.useFetchDistrict({});

  useEffect(() => {
    if (contact && isUpdate) {
      contactForm.fill({
        contactName: contact.contactName,
        contactSurname: contact.contactSurname,
        contactTitle: contact.contactTitle?.id,
        contactBirthDay: parseDateSafe(contact.contactBirthDay),
        contactSection: contact.contactSection,
        contactJob: contact.contactJob,
        contactisRelevantPerson: contact.contactisRelevantPerson,
        contactisInPromotionList: contact.contactisInPromotionList,
        contactComment: contact.contactName,
        departmentsConcerned: toIdList(contact.departmentsConcerned, 'department'),
        contactisInEmailList: contact.contactisInEmailList,
        contactisInSmsList: contact.contactisInSmsList,

        contactAddressLine1: contact.contactAddress?.addressLine1,
        contactAddressLine2: contact.contactAddress?.addressLine2,
        contactCountry: contact.contactAddress?.country?.id,
        contactCity: contact.contactAddress?.city?.id,
        contactState: contact.contactAddress?.state?.id,
        contactZipCode: contact.contactAddress?.zipCode,
      });

      setInformationList(contact.contactInformation || []);
    }
  }, [contact, setInformationList]);

  useEffect(() => {
    if (contactForm.value.contactCity) {
      getStates({ id: contactForm.value.contactCity });
    }
  }, [contactForm.value.contactCity, getStates]);

  const save = useCallback(async () => {
    const result = contactForm.parse();
    if (result.success) {
      const value = result.data;

      const contact: Contact = {
        ...value,
        deleted: false,
        contactAccount: { id: account?.id },
        contactBirthDay: value.contactBirthDay?.toString(),
        contactTitle: { id: value.contactTitle },
        departmentsConcerned: toModelList<ContactDepartmentConcerned>(value.departmentsConcerned, 'department'),
        contactAddress: adressFormToModel(value.contactAddressLine1, value.contactAddressLine2, value.contactCountry, value.contactCity, value.contactState, value.contactZipCode),
      };

      contact.contactInformation = [...informationList];

      await API.CONTACT.save(contact);
    } else {
      console.log(result.error);
      contactForm.setAllTouched();
    }
  }, [contactForm, informationList]);

  const addInformation = useCallback(async () => {
    const result = contactInformationForm.parse();
    if (result.success) {
      const value = result.data;
      const information: ContactInformation = {
        ...value,
        contactType: { id: value.contactType, detail: contactType?.find((item) => item.id === value.contactType)?.detail },
      };

      setInformationList([...informationList, information]);
    } else {
      console.log(result.error);
      contactForm.setAllTouched();
    }
  }, [contactInformationForm, informationList, setInformationList]);

  return (
    <>
      <div className="overflow-auto p-4">
        <Form className="w-full grid grid-cols-2 gap-5 gap-x-8" autoComplete="off">
          <TextInput {...contactForm.props('contactName')} type="text" label="Ad" />
          <TextInput {...contactForm.props('contactSurname')} type="text" label="Soyad" />

          <ComboBox {...contactForm.props('contactTitle')} label="Ünvan" defaultItems={contactTitle} itemKey="id" itemLabel="detail" />
          <DatePicker {...contactForm.props('contactBirthDay')} label="Doğum Tarihi" />

          <TextInput {...contactForm.props('contactSection')} type="text" label="Bölümü" />
          <TextInput {...contactForm.props('contactJob')} type="text" label="Görevi" />

          <ComboBox {...contactForm.props('contactisRelevantPerson')} label="Firma İlgili Kişisi mi?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
          <ComboBox {...contactForm.props('contactisInPromotionList')} label="Promosyon İsteniyor mu?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />

          <TextInput {...contactForm.props('contactComment')} type="text" label="Açıklama" textArea />
          <SelectListBox
            {...contactForm.props('departmentsConcerned')}
            name="firmaTuruList"
            label="İlgili Olduğu Bölüm/Birim"
            defaultItems={department}
            itemKey="id"
            itemLabel="detail"
          />

          <ComboBox {...contactForm.props('contactisInEmailList')} label="Toplu E-posta Bildirim İsteniyor Mu?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
          <ComboBox {...contactForm.props('contactisInSmsList')} label="Bildirim İsteniyor mu?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />

          <Accordion className="col-span-2" header="Firma İletişim Bilgileri" open>
            <div className="w-full grid grid-cols-3 gap-5 gap-x-8">
              <div className="flex flex-col min-h-64 gap-5">
                <TextInput {...contactForm.props('contactAddressLine1')} type="text" label="Açık Adres" textArea />
                <TextInput {...contactForm.props('contactAddressLine2')} type="text" label="Adres Kısım 2" textArea />
              </div>
              <div className="col-span-2 grid grid-cols-2  gap-5 gap-x-8 grid-rows-[min-content_min-content] ">
                <ComboBox {...contactForm.props('contactCountry')} label="Ülke" defaultItems={country} itemKey="id" itemLabel="name" />
                <ComboBox {...contactForm.props('contactCity')} label="Şehir / İl / Bölge" defaultItems={city} itemKey="id" itemLabel="cityName" />
                <ComboBox {...contactForm.props('contactState')} label="Semt / İlçe" defaultItems={states} itemKey="id" itemLabel="cityName" />
                <TextInput {...contactForm.props('contactZipCode')} type="text" label="Posta Kodu" />
              </div>
            </div>
          </Accordion>

          <Accordion className="col-span-2" header="İletişim Kişileri" open>
            <div className="mb-2 flex items-end gap-4">
              <ComboBox {...contactInformationForm.props('contactType')} label="İletişim Türü" defaultItems={contactType} itemKey="id" itemLabel="detail" />
              <TextInput {...contactInformationForm.props('contactTypeDetail')} type="text" label="İletişim Detayı" />
              <TextInput {...contactInformationForm.props('extensionNumber')} type="text" label="Dahili" />

              <Button variant="secondary" className="h-8" onPress={addInformation}>
                <BadgePlus size={18} />
                Ekle
              </Button>
            </div>
            <Table className="w-full">
              <TableHeader>
                <Column isRowHeader>No</Column>
                <Column>İletişim Türü</Column>
                <Column>İletişim Detay</Column>
                <Column>Dahili</Column>
              </TableHeader>

              <TableBody>
                {informationList.map((row, index) => (
                  <Row key={row.id}>
                    <Cell>{index + 1}</Cell>
                    <Cell>{row.contactType?.detail}</Cell>
                    <Cell>{row.contactTypeDetail}</Cell>
                    <Cell>{row.extensionNumber}</Cell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </Accordion>
        </Form>
      </div>
      <div className="flex justify-end bg-gray-200  rounded-b-lg px-6 p-2 border-t border-gray-300 ">{!isUpdate && <Button onPress={save}>Kaydet</Button>}</div>
    </>
  );
};
