import * as API from 'api';
import { Cell, Column, Dialog, Modal, ModalHeader, Row, Table, TableHeader } from 'components';
import { useTableList } from 'hooks';
import { useEffect } from 'react';
import { TableBody } from 'react-aria-components';
import { Papers } from 'types';

export interface EvraklarDosyaProps {
  paper?: Papers;
}

export const EvraklarDosyaDialog = (props: EvraklarDosyaProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh] min-h-96 ">
        <ModalHeader header="Evrak Dokümanları" />
        <EvraklarDosya {...props}></EvraklarDosya>
      </Dialog>
    </Modal>
  );
};

export const EvraklarDosya = (props: EvraklarDosyaProps) => {
  const [documentList, getDocumentList] = API.PAPERS_DOCUMENT.useFetchByPapersId({});

  useEffect(() => {
    if (props.paper) {
      getDocumentList(null, { papersId: props.paper.id });
    }
  }, [props.paper, getDocumentList]);

  const documentTableList = useTableList(documentList, 'id', 'ascending');

  return (
    <div className="p-2">
      <Table className="w-full" selectionMode="multiple">
        <TableHeader>
          <Column isRowHeader>No</Column>
          <Column>Dosya Adı</Column>
          <Column>Dosya Türü</Column>
          <Column>Boyut</Column>
        </TableHeader>

        <TableBody items={documentTableList.items}>
          {(row) => (
            <Row key={row.id}>
              <Cell>{row.index + 1}</Cell>
              <Cell>{row.name}</Cell>
              <Cell>{row.contentType}</Cell>
              <Cell>{row.fileSize}</Cell>
            </Row>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
