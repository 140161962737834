import * as API from 'api';
import { Accordion, Button, Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { BadgePlus } from 'lucide-react';
import { useEffect } from 'react';
import { DialogTrigger, TableBody } from 'react-aria-components';
import { Account, Contact, Department, Employee } from 'types';
import { FirsatKayitDialog } from './FirsatKayit';
import { formatDate } from 'utils';

export interface FirsatlarProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const Firsatlar = (props: FirsatlarProps) => {
  const [list, getList, loading] = API.OPPORTUNITY.useFetchByAccountId({});

  useEffect(() => {
    if (props.account) {
      getList(null, { accountId: props.account.id });
    }
  }, [props.account, getList]);

  const tableList = usePageTableList({ list, loading });

  return (
    <Accordion header="Fırsatlar" open>
      <div className="mb-2">
        <DialogTrigger>
          <Button variant="secondary">
            <BadgePlus size={18} />
            Ekle
          </Button>
          <FirsatKayitDialog {...props} />
        </DialogTrigger>
      </div>
      <div className="grid gap-1">
        <Table {...tableList}>
          <TableHeader>
            <Column isRowHeader>No</Column>
            <Column>Fırsat Adı</Column>
            <Column>Son Tarih</Column>
            <Column>Durumu</Column>
            <Column>Müşteri Temsilcisi</Column>
            <Column>Mevcut Durumda</Column>
          </TableHeader>

          <TableBody {...tableList}>
            {(row) => (
              <Row key={row.id}>
                <Cell>{row.id}</Cell>
                <Cell>{row.opportunityTitle}</Cell>
                <Cell>{formatDate(row.dueDate)}</Cell>
                <Cell>{row.status?.detail}</Cell>
                <Cell>
                  {row.customerRepr?.name} {row.customerRepr?.surname}
                </Cell>
                <Cell>{row.realized}</Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        <Paginator {...tableList} />
      </div>
    </Accordion>
  );
};
