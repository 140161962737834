
export const toIdList = <T extends object>(list?: T[], key?: keyof T) => {
  if (list) {
    if (key) {
      return list.map((item) => (item[key] as any).id) as number[];
    }
    return list.map((item) => (item as any).id) as number[];
  }

  return [] as number[];
};

export const toModelList = <T>(idList?: number[], key?: keyof T) => {
  if (idList) {
    if (key) {
      return idList.map((id) => ({ [key]: { id } })) as T[];
    }

    return idList.map((id) => ({ id })) as T[];
  }

  return [] as T[];
};

