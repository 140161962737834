import { City, Country } from './sozluk';

export type Adress = Partial<{
  id: number;
  city: City;
  state: City;
  country: Country;
  addressLine1: string;
  addressLine2: string;
  cityText: string;
  stateText: string;
  zipCode: string;
}>;

export type BillingAddress = Adress;
export type ContactAddress = Adress;
export type ShippingAddress = Adress;

export const adressFormToModel = (addressLine1? : string, addressLine2?: string, country?: number, city?: number, state? : number, zipCode? :string) => {
 return {
  addressLine1,
  addressLine2,
  zipCode,
  country: { id: country },
  city: { id: city },
  state: { id: state },
 } as Adress;
}