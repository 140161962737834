import { Account } from "./Account";
import { Contact } from "./Contact";
import { Employee } from "./Employee";
import { FeeList } from "./FeeList";
import { TaskAwaitingReminderType, TaskStatus } from "./sozluk";

export type TaskComment = Partial<{
  id: number;
  comment: string;
  insertDate: string;
  employee: Employee;
  deleted: boolean;
}>;


export type Task = Partial<{
  id: number;

  responsibleUser: Employee;

  viewerUser: Employee[];
  senderRef: string;
  keywords: string;
  feeList: FeeList;
  dueDate: string;
  finalDueDate: string;
  batchSize: number;

  taskComment: TaskComment[];
  taskStatus: TaskStatus;

  createdBy: Employee;
  createdAt: string;
  deleted: boolean;

  account: Account;
  otherAccount: string;

  contact: Contact;
  otherContact: string;
  apbRef: string;
  private: boolean;
  otherProcess: string;
  messageArrivalDate: string;
  // List<IncomingMessage> messageList: string;
  // List<OutgoingMessage> outgoingMessageList: string;
  closedAt: string;
  applicationNumber: string;
  awaitingReason: string;

  urgent: boolean;

  taskAwaitingReminder: TaskAwaitingReminderType[];
}>;
