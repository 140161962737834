import { useCallback, useMemo, useState } from 'react';
import { SortDescriptor, SortDirection } from 'react-stately';
import orderBy from 'just-order-by';
import get from 'just-safe-get';
import clamp from 'just-clamp';
import { TableEmptyMessage } from 'components';

export type TableHook<T extends object> = {
  list?: T[];
  loading?: boolean;
  column?: string;
  direction?: SortDirection;
  pageSize?: number;
  defaultValue?: T[];
};

export const useTableList = <T extends object>(list?: T[], column?: string, direction: SortDirection = 'ascending', defaultValue: T[] = []) => {
  let [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: column,
    direction: direction,
  });

  let items = useMemo(() => {
    if (list) {
      let items = list.slice();

      if (sortDescriptor.column) {
        items = orderBy(items, [
          {
            property(value) {
              return get(value, sortDescriptor.column as string);
            },
            order: sortDescriptor.direction === 'ascending' ? 'asc' : 'desc',
          },
        ]);
      }

      items = items.map((item, index) => ({ ...item, index }));

      return items.slice(0, 100);
    }

    return defaultValue;
  }, [list, sortDescriptor]) as Iterable<T & { index: number }>;

  return { items, sortDescriptor, setSortDescriptor };
};

export const usePageTableList = <T extends object>({ list, loading = false, column, direction = 'ascending', pageSize = 10, defaultValue = [] }: TableHook<T>) => {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: column,
    direction: direction,
  });

  const pageNumber = useMemo(() => {
    return pageIndex + 1;
  }, [pageIndex]);

  const pageCount = useMemo(() => {
    const length = list?.length || 0;
    return Math.max(Math.ceil(length / pageSize), 1);
  }, [list, pageSize]);

  const items = useMemo(() => {
    if (list) {
      let items = list.slice();

      if (sortDescriptor.column) {
        items = orderBy(items, [
          {
            property(value) {
              return get(value, sortDescriptor.column as string);
            },
            order: sortDescriptor.direction === 'ascending' ? 'asc' : 'desc',
          },
        ]);
      }

      items = items.map((item, index) => ({ ...item, index }));

      return items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }

    return defaultValue;
  }, [list, sortDescriptor, pageIndex, pageSize]) as Array<T & { index: number }>;

  const setPage = useCallback(
    (_pageIndex: number) => {
      const pageIndexClamped = clamp(0, _pageIndex, pageCount - 1);

      setPageIndex(pageIndexClamped);
    },
    [pageCount, setPageIndex],
  );

  const firstPage = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const lastPage = useCallback(() => {
    setPage(pageCount - 1);
  }, [pageCount, setPage]);

  const prePage = useCallback(() => {
    setPage(pageIndex - 1);
  }, [pageIndex, setPage]);

  const nextPage = useCallback(() => {
    setPage(pageIndex + 1);
  }, [pageIndex, setPage]);

  const renderEmptyState = useCallback(() => {
    return <TableEmptyMessage loading={loading} />;
  }, [loading]);

  return { items, loading, sortDescriptor, setSortDescriptor, pageCount, pageIndex, pageNumber, pageSize, setPage, prePage, nextPage, firstPage, lastPage, renderEmptyState };
};
