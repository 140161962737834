import { z } from 'zod';
import { Employee } from './Employee';
import { Account } from './Account';

export type AccountComment = Partial<{
  id: number;
  comment: string;
  insertDate: string;
  employee: Employee;
  deleted: boolean;
  account : Account;
}>;

export type AccountCommentFormable = Partial<{
  comment: string;
}>;

export const AccountCommentForm = z.object({
  comment: z.string().min(1, 'Açıklama girmelisiniz.'),
});
