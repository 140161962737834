import { FieldError, FileTrigger, Input, Label, Text, TextArea, TextField, TextFieldProps, composeRenderProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Button } from './Button';
import { useState } from 'react';

export interface TextInputProps extends TextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string;
  textArea?: boolean;
}

let textInput = tv({
  slots: {
    base: 'relative flex flex-col items-start gap-0.5',
    label: 'font-semibold  pl-0.5',
    input:
      'w-full bg-white border border-slate-300 rounded p-1.5 px-2 outline-none focus:outline focus:outline-2 focus:outline-theme-300 focus:outline-offset-1 transition-colors duration-500',
    description: 'text-xs pl-0.5',
    error: 'absolute text-xs pl-0.5 text-red-600 -bottom-4',
  },
  variants: {
    isInvalid: {
      true: {
        input: 'border-red-600',
      },
    },
    isDisabled: {
      true: {
        label: 'text-slate-300',
        input: 'bg-slate-100 border-slate-200',
      },
    },
    isReadOnly: {
      true: {
        input: 'bg-slate-50 border-slate-200',
      },
    },
  },
});

export const TextInput = (props: TextInputProps) => {
  const { base, label, input, description, error } = textInput({
    isInvalid: props.isInvalid,
    isDisabled: props.isDisabled,
    isReadOnly: props.isReadOnly,
  });

  return (
    <TextField {...props} validationBehavior="aria" className={composeRenderProps(props.className, (className, renderProps) => base({ ...renderProps, className }))}>
      <Label className={label()}>
        {props.label} {props.isRequired && <span className="text-red-600">*</span>}
      </Label>
      {!Boolean(props.textArea) && <Input className={input()} autoComplete={props.autoComplete || 'off'} role="presentation" />}
      {Boolean(props.textArea) && <TextArea className={input({ className: 'h-full resize-none ' })} autoComplete="off" role="presentation" rows={3} />}
      {props.description && (
        <Text className={description()} slot="description">
          {props.description}
        </Text>
      )}
      <FieldError className={error()}>{props.errorMessage}</FieldError>
    </TextField>
  );
};

export const FileInput = (props: TextInputProps) => {
  let [file, setFile] = useState<any>(null);

  const { base, label, input, description, error } = textInput({
    isInvalid: props.isInvalid,
    isDisabled: props.isDisabled,
    isReadOnly: props.isReadOnly,
  });

  return (
    <div className={base()}>
      <Label className={label()}>
        {props.label} {props.isRequired && <span className="text-red-600">*</span>}
      </Label>
      <div className="flex row gap-2 items-center">
        <FileTrigger
          onSelect={(e: any) => {
            console.log(e);
            let files = Array.from(e);
            let filenames = files.map((file: any) => file.name);
            setFile(filenames);
          }}
          acceptedFileTypes={['image/png']}
        >
          <Button variant="secondary">Dosya Ekle</Button>
        </FileTrigger>
        {file && file}
      </div>
      {props.description && (
        <Text className={description()} slot="description">
          {props.description}
        </Text>
      )}
      <FieldError className={error()}>{props.errorMessage}</FieldError>
    </div>
  );
};
