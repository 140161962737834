import { Employee } from "./Employee";

export type CustomerSatisfactionForm = Partial<{
  id: number;
  deleted: boolean;
  version: string;
  totalPoint: number;
  employee: Employee;
  additionalInfo: string;
  point1: number;
  point2: number;
  point3: number;
  point4: number;
  point5: number;
  point6: number;
  point7: number;
  point8: number;
}>;
