import { composeRenderProps, Button as RACButton, ButtonProps as RACButtonProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';

export interface ButtonProps extends RACButtonProps {
  variant?: 'primary' | 'secondary' | 'dark' | 'danger' | 'text';
}

let button = tv({
  base: 'p-1 px-3 rounded transition-colors duration-300 flex items-center justify-center gap-1.5',
  variants: {
    variant: {
      primary:
        'text-white bg-theme-500 hover:bg-theme-600 pressed:bg-theme-800 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-theme-300 focus-visible:outline-offset-1',
      secondary:
        'text-gray-800 bg-slate-200 hover:bg-slate-300 pressed:bg-slate-400 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-theme-300 focus-visible:outline-offset-1',
      dark: 'text-white bg-slate-600 hover:bg-slate-500 pressed:bg-slate-400 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-theme-300 focus-visible:outline-offset-1',
      danger:
        'text-white bg-red-600 hover:bg-red-700 pressed:bg-red-900 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-theme-300 focus-visible:outline-offset-1',
      text: 'text-gray-800 bg-transparent hover:bg-gray-500/20 pressed:bg-gray-500/40 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-theme-300 focus-visible:outline-offset-1',
    },
    isDisabled: {
      true: 'bg-gray-100 text-gray-300 border-black/5',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export const Button = (props: ButtonProps) => {
  return (
    <RACButton {...props} className={composeRenderProps(props.className, (className, renderProps) => button({ ...renderProps, variant: props.variant, className }))}></RACButton>
  );
};
