import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormHook } from 'types';
import { isObjectEmpty } from 'utils';
import { z } from 'zod';

export const useForm = <T extends object>(Model: z.AnyZodObject, initialValue: Partial<T>) => {
  const [value, setValue] = useState(initialValue);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [valids, setValids] = useState<Record<string, boolean>>({});
  const [toucheds, setToucheds] = useState<Record<string, boolean>>({});
  const [selected, setSelected] = useState<Partial<T> | undefined>();

  useEffect(() => {
    const parseResult = Model.safeParse(value);

    setValid(parseResult.success);

    let _errors: Record<string | number, string> = {};
    if (!parseResult.success) {
      _errors = Object.fromEntries(parseResult.error.issues.map(({ path, message }) => [path[0], message]));
    }

    setErrors(_errors);
    setValids(Object.fromEntries(Object.keys(Model.shape).map((key) => [key, Boolean(_errors[key])])));
  }, [value, setValid, setErrors, setValids]);

  const parse = useCallback(() => {
    return Model.safeParse(value) as z.SafeParseReturnType<Partial<T>, Partial<T>>;
  }, [value, setToucheds]);

  const setField = useCallback(
    (fieldName: keyof T) => {
      return (feildValue: unknown) => {
        setValue((_value) => ({ ..._value, [fieldName]: feildValue }));
        window.setTimeout(() => {
          setToucheds((_toucheds) => ({ ..._toucheds, [fieldName]: true }));
        }, 0);
      };
    },
    [setValue, setToucheds]
  );

  const setAllTouched = useCallback(() => {
    setToucheds(Object.fromEntries(Object.keys(Model.shape).map((key) => [key, true])));
  }, [initialValue, setToucheds]);

  const props = useCallback(
    (fieldName: keyof T) => {
      return {
        value: (value[fieldName] === null || value[fieldName] === undefined )  ? '' as any : value[fieldName],
        errorMessage: errors[fieldName as string],
        onChange: setField(fieldName),
        isInvalid : toucheds[fieldName as string] && valids[fieldName as string]
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const reset = useCallback(() => {
    setValue(initialValue);
    setToucheds({});
    setSelected(undefined);
  }, [setValue, setToucheds, setSelected]);

  const fill = useCallback(
    (newValue: Partial<T>) => {
      if(newValue && !isObjectEmpty(newValue)) {
        setSelected(newValue);
        setValue({ ...newValue });
      }
    },
    [setSelected, setValue]
  );

  const patch = useCallback(
    (patchValue: Partial<T>) => {
      if(patchValue) {
        setValue({ ...value, ...patchValue });
      }
    },
    [value, setValue]
  );

  const validationErrors = useMemo(() => {
    return Object.fromEntries(
      Object.keys(Model.shape)
        .filter((key) => toucheds[key])
        .map((key) => [key, errors[key]])
    );
  }, [valid, errors, toucheds]);

  const touchedAndInvalid = useMemo(() => {
    const someTouched = Object.entries(toucheds).some(Boolean)
    return !valid && someTouched
  }, [valid, toucheds]);

  return {
    value,
    valid,
    errors,
    validationErrors,
    toucheds,
    valids,
    props,
    parse,
    setValue,
    setField,
    setAllTouched,
    reset,
    fill,
    patch,
    selected,
    touchedAndInvalid
  } as FormHook<T>;
};
