import { Key, useAsyncList } from 'react-stately';
import { request } from 'utils';
import { ComboBox, ComboBoxProps } from './ComboBox';
import {  useCallback } from 'react';

export interface ComboBoxRemoteProps<T extends object> extends ComboBoxProps<T> {
  url: string;
  searchKey: string;
}

export const ComboBoxRemote = <T extends object>(props: ComboBoxRemoteProps<T>) => {
  let list = useAsyncList<T>({
    async load({ signal, filterText }) {
      const response = await request(props.url, 'POST', null, { [props.searchKey] : filterText }, {signal}, true);

      return {
        items: (response.data || []) as Iterable<T>,
      };
    },
  });


  const _onChange = useCallback(
    (key: Key) => {
      if (props.onChange && key) {
        props.onChange(key);
      }
      if(key) {
        list.setFilterText(list.items.find(item => item[props.itemKey] === key)?.[props.itemLabel] + '');
      }
     
    },
    [props.onChange, list, list.items]
  );

  return <ComboBox {...props}  onChange={_onChange} items={list.items} inputValue={list.filterText} onInputChange={list.setFilterText} />;
};
