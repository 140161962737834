export * as SOZLUK from './sozluk';
export { default as USERS } from './users';
export { default as ACCOUNT } from './account';
export { default as EMPLOYEE } from './employee';
export { default as CONTACT } from './contact';
export { default as ACCOUNT_COMMENT } from './accountComment';
export { default as ACTIVITY } from './activity';
export { default as FEED_BACK } from './feedBack';
export { default as OPPORTUNITY } from './opportunity';
export { default as PROPOSAL } from './proposal';
export { default as TASK } from './task';
export { default as CRM_FILES } from './crmFiles';
export { default as PAPERS } from './papers';
export { default as PAPERS_DOCUMENT } from './papersDocument';
