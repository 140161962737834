import { useFetch } from 'hooks';
import { Contact, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchByAccountId: (config: UseFetchOptions, accountId: number) =>
    useFetch<Contact[]>('/contact/get-contact-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  useFetchActiveByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<Contact[]>('/contact/get-active-contact-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<Contact[]>('/contact/get-contact-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchSimpleSearch: (config: UseFetchOptions) => useFetch<Contact[]>('/account/contact-simple-search', { method: 'POST', ...config }),
  save: async (contact: Contact) => request('/contact/save', 'POST', contact),
  delete: async (contact: Contact) => request('/contact/save', 'POST', null, { id: contact.id }),
};
