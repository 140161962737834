import { CalendarDate } from '@internationalized/date';
import { Account } from './Account';
import { Contact } from './Contact';
import { Employee } from './Employee';
import { Department, FeedBackResult, FeedBackStatus, FeedBackSubjectType, FeedBackType } from './sozluk';
import { z } from 'zod';

export type FeedBack = Partial<{
  id: number;
  account: Account;
  trackingNumber: string;
  feedBackDate: string;
  comment: string;
  feedBackContact: Contact;
  feedBackStatus: FeedBackStatus;
  assignedEmployee: Employee;
  feedBackType: FeedBackType;
  feedBackSubjectType: FeedBackSubjectType;
  feedBackRelatedDepartment: Department[];
  feedBackResult: FeedBackResult;
  resultDate: string;
  // List<FeedBackDocument> feedBackDocument: string;
  resultComment: string;
  deleted: boolean;
  version: string;
}>;

export type FeedBackFormable = Partial<{
  trackingNumber: string;
  assignedEmployee: number;
  feedBackDate: CalendarDate;
  feedBackRelatedDepartment: number[];
  comment: string;
  feedBackContact: number;
  feedBackStatus: number;
  feedBackType: number;
  feedBackSubjectType: number;

  feedBackResult: number;
  resultDate: CalendarDate;
  resultComment: string;
}>;

export const FeedBackForm = z.object({
  trackingNumber: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  assignedEmployee: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  feedBackDate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  feedBackRelatedDepartment: z.array(z.number()).nullish(),
  comment: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  feedBackStatus: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  feedBackType: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  feedBackSubjectType: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),

  feedBackResult: z.number().nullish(),
  resultDate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  resultComment: z.string().nullish(),
});
