import { HTMLAttributes } from 'react';

export interface BoxProps extends HTMLAttributes<HTMLElement> {
  elementType?: string;
}

export const Box = (props: BoxProps) => {
  let { elementType: ElementType = 'div', ...domProps } = props;

  return <ElementType {...domProps}></ElementType>;
};
