import { MenuBreadcrumbs, Sidebar } from 'components';
import { Dot } from 'lucide-react';
import * as _pages from 'pages';
import { useState } from 'react';
import { RouterProvider, Text } from 'react-aria-components';
import Marquee from 'react-fast-marquee';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { KullaniciModel } from 'types';
import { TheUser, UserContext } from 'contexts';
import { Login } from './pages/Login';

const pages = Object.entries(_pages);

export const AppRouted = () => {
  const navigate = useNavigate();

  return (
    <RouterProvider navigate={navigate}>
      <div className="grid grid-flow-col grid-cols-[240px_1fr] overflow-hidden">
        {true ? <Sidebar /> : <div></div>}
        <div className="grid grid-flow-row grid-rows-[30px_1fr] pt-3 px-4 lg:px-8 xl:px-16  pb-10  overflow-hidden">
          <div>
            <MenuBreadcrumbs />
          </div>
          <Routes>
            {pages.map(([key, Comp]) => (
              <Route key={key} path={Comp.path} element={<Comp />} />
            ))}
          </Routes>
        </div>
      </div>
      <div className="bg-white absolute flex items-center bottom-0 left-[50px] pr-[100px]">
        <Text className="whitespace-nowrap font-semibold text-red-600">Ödeme Sorunlu Firmalar : </Text>
        <div className="px-4 pr-10 w-full">
          <Marquee play={true} pauseOnHover gradient gradientWidth={80} className="max-w-[80vw]">
            <div className="text-red-600 text-xs flex items-center">
              <span>012242 - Standard Snacks SRL</span>
              <Dot className="text-slate-800 mx-2" />
              <span>011976 - J. Lee & Associates Patents & TradeMarks</span>
              <Dot className="text-slate-800 mx-2" />
              <span>011822 - Akerman LLP</span>
              <Dot className="text-slate-800 mx-2" />
              <span>018493 - Ing. Claudio Baldi s.r.l</span>
              <Dot className="text-slate-800 mx-2" />
              <span>012242 - Standard Snacks SRL</span>
              <Dot className="text-slate-800 mx-2" />
              <span>011976 - J. Lee & Associates Patents & TradeMarks</span>
              <Dot className="text-slate-800 mx-2" />
              <span>011822 - Akerman LLP</span>
              <Dot className="text-slate-800 mx-2" />
              <span>018493 - Ing. Claudio Baldi s.r.l</span>
            </div>
          </Marquee>
        </div>
      </div>

      <Toaster position="top-right" />
    </RouterProvider>
  );
};

export const App = () => {
  const [user, setUser] = useState<KullaniciModel>(TheUser);
  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>{!user?.kullaniciAdi ? <AppRouted /> : <Login setUser={setUser} />}</BrowserRouter>
    </UserContext.Provider>
  );
};
