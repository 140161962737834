import { Check } from 'lucide-react';
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxProps as AriaListBoxProps,
  Collection,
  Header,
  ListBoxItemProps,
  Section,
  SectionProps,
  composeRenderProps
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { composeTailwindRenderProps, focusRing } from 'utils';

interface ListBoxProps<T> extends Omit<AriaListBoxProps<T>, 'layout' | 'orientation'> {}

export function ListBox<T extends object>(
  { children, ...props }: ListBoxProps<T>
) {
  return (
    <AriaListBox {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-0.5 outline-0 p-1 border border-gray-300 rounded-lg overflow-auto')}>
      {children}
    </AriaListBox>
  );
}

export const itemStyles = tv({
  extend: focusRing,
  base: 'group relative flex items-center gap-20 cursor-default select-none py-1.5 px-2.5 rounded-md will-change-transform text-sm',
  variants: {
    isSelected: {
      false: 'text-slate-700 hover:bg-slate-200  -outline-offset-2',
      true: 'bg-theme-500 text-white -outline-offset-4 outline-white'
    },
    isDisabled: {
      true: 'text-slate-300'
    }
  }
});

export function ListBoxItem(props: ListBoxItemProps) {
  let textValue = props.textValue || (typeof props.children === 'string' ? props.children : undefined);
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, children => <>
        {children}
      </>)}
    </AriaListBoxItem>
  );
}
;