import { z } from 'zod';
import { Account } from './Account';
import { Employee } from './Employee';
import { PapersType } from './sozluk';
import { CalendarDate } from '@internationalized/date';

export type Papers = Partial<{
  id: number;
  account: Account;
  papersDate: string;
  papersDueDate: string;
  addedDate: string;
  papersType: PapersType;
  papersDocument: PapersDocument[];
  employee: Employee;
  isDeleted: boolean;
}>;

export type PapersDocument = Partial<{
  id: number;
  name: string;
  contentType: string;
  data: string;
  fileSize: number;
  isDeleted: boolean;
  insertDate: string;
  papers: Papers;
}>;

export type PapersSimpleSearchRequestModel = Partial<{
  accountId: number;
  papersDateStart: string;
  papersDateEnd: string;
  papersDueDateStart: string;
  papersDueDateEnd: string;
  papersTypeId: number;
  accountWorkingStatus: number;
  accountGroup: number;
}>;

export const PaperSearchForm = z.object({
  accountId: z.number().nullish(),
  papersTypeId: z.number().nullish(),
  accountWorkingStatus: z.number().nullish(),
  accountGroup: z.number().nullish(),
  papersDateStart: z
  .custom((val: unknown) => Boolean(val), 'Zorunludur')
  .nullish(),
  papersDateEnd: z
  .custom((val: unknown) => Boolean(val), 'Zorunludur')
  .nullish(),
  papersDueDateStart: z
  .custom((val: unknown) => Boolean(val), 'Zorunludur')
  .nullish(),
  papersDueDateEnd: z
  .custom((val: unknown) => Boolean(val), 'Zorunludur')
  .nullish(),
});