import { ChevronRight } from 'lucide-react';
import React, { HTMLAttributes, useMemo } from 'react';
import { Breadcrumb as AriaBreadcrumb, Breadcrumbs as AriaBreadcrumbs, BreadcrumbProps, BreadcrumbsProps, Link, LinkProps } from 'react-aria-components';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { menu, menuFlat } from '../constants';
import { Menu } from 'types';

export function MenuBreadcrumbs() {
  const location = useLocation();

  const menuItem = useMemo(() => {
    return menuFlat.find((item) => item.path === location.pathname);
  }, [location.pathname]);


  return (
    <Breadcrumbs>
      {menuItem?.flow.map((item: Menu) => (
        <Breadcrumb key={item.path} isLast={item.isLast} className="outline-0 select-none">
          <span className="text-sm tracking-wide outline-0 outline-none">{item.title}</span>
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
}

export function Breadcrumbs<T extends object>(props: BreadcrumbsProps<T>) {
  return <AriaBreadcrumbs {...props} className={twMerge('flex gap-1', props.className)} />;
}

export function Breadcrumb(props: BreadcrumbProps & LinkProps & { isLast?: boolean }) {
  return (
    <AriaBreadcrumb {...props} className={twMerge('flex items-center gap-1', props.className)}>
      <Link {...props} />
      {!props.isLast && <ChevronRight className="w-4 h-4 text-gray-600 " />}
    </AriaBreadcrumb>
  );
}
