import { z } from 'zod';
import { Account } from './Account';
import { CustomerSatisfactionForm } from './CustomerSatisfactionForm';
import { Employee } from './Employee';
import { ActivityType } from './sozluk';
import { CalendarDate } from '@internationalized/date';

export type Activity = Partial<{
  id: number;
  subject: string;
  activitydate: string;
  activitydueDate: string;
  activityAccount: Account;
  // List<ActivityDocument> documents: string;
  employeesInCharge: Employee[];
  type: ActivityType;
  status: ActivityType;
  deleted: boolean;
  version: string;
  form: CustomerSatisfactionForm;
}>;

export type ActivityFormable = Partial<{
  subject: string;
  activitydate: CalendarDate;
  activitydueDate: CalendarDate;
  employeesInCharge: number[];
  type: number;
  status: number;
}>;

export const ActivityForm = z.object({
  subject: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  activitydate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  activitydueDate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  employeesInCharge: z.array(z.number()).nullish(),
	type: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  status: z.number().nullish(),
});
