import { z } from "zod";

export type CrmFiles = Partial<{
  id: number;
  fileNumber: string;
  firstInfo: string;
  secondInfo: string;
  account: number;
  fileReferance: string;
  zipCode: string;
}>;

export type CrmFilesFormable = Partial<{
  fileReferance: string;
}>;

export const CrmFilesForm = z.object({
  fileReferance: z.string().nullish(),
});

export const FileRefanceOptions = [
  {id: 'Yim', name : 'Yurt İçi Marka', children : [
    {id: 'Yim Araştırma', name : 'Araştırma Dosyası'},
    {id: 'Yim Başvuru', name : 'Başvuru Dosyası'},
    {id: 'Yim İtiraz', name : 'İtiraz Dosyası'},
  ]},
  {id: 'Yda', name : 'Yurt Dışına Marka', children : [
    {id: 'Yda Araştırma', name : 'Araştırma Dosyası'},
    {id: 'Yda Başvuru', name : 'Başvuru Dosyası'},
    {id: 'Yda İtiraz', name : 'İtiraz Dosyası'},
  ]},
  {id: 'Ydn', name : 'Yurt Dışından Marka', children : [
    {id: 'Ydn Araştırma', name : 'Araştırma Dosyası'},
    {id: 'Ydn Başvuru', name : 'Başvuru Dosyası'},
    {id: 'Ydn İtiraz', name : 'İtiraz Dosyası'},
  ]},
  {id: 'Patent', name : 'Patent', children : [
    {id: 'Patent Araştırma', name : 'Araştırma Dosyası'},
    {id: 'Patent Başvuru', name : 'Başvuru Dosyası'},
    {id: 'Patent İtiraz', name : 'İtiraz Dosyası'},
  ]},
  {id: 'Tasarım', name : 'Tasarım', children : [
    {id: 'Tasarım Araştırma', name : 'Araştırma Dosyası'},
    {id: 'Tasarım Başvuru', name : 'Başvuru Dosyası'},
    {id: 'Tasarım İtiraz', name : 'İtiraz Dosyası'},
  ]},
]
