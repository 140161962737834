import * as API from 'api';
import { Button, ComboBox, DatePicker, Dialog, FileInput, Modal, ModalFooter, ModalHeader, SelectListBox, TextInput } from 'components';
import { useForm } from 'hooks';
import { useCallback } from 'react';
import { Form } from 'react-aria-components';
import { Account, Activity, ActivityForm, ActivityFormable, Employee } from 'types';
import { toModelList } from 'utils';

export interface ToplantiKayitProps {
  account?: Account;
  employeeList?: Employee[];
}

export const ToplantiKayitDialog = (props: ToplantiKayitProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh]">
        <ModalHeader header="Toplantı Kayıt" />
        <ToplantiKayit {...props}></ToplantiKayit>
      </Dialog>
    </Modal>
  );
};

export const ToplantiKayit = ({ account, employeeList }: ToplantiKayitProps) => {
  const form = useForm<ActivityFormable>(ActivityForm, {});

  const [activityType] = API.SOZLUK.ACTIVITY_TYPE.useFetch({ init: true });

  const save = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      const activity: Activity = {
        ...value,
        activityAccount: account,
        type: { id: value.type },
        status: { id: value.status },
        activitydate: value.activitydate?.toString(),
        activitydueDate: value.activitydueDate?.toString(),
        employeesInCharge: toModelList<Employee>(value.employeesInCharge),
        deleted: false,
      };

      await API.ACTIVITY.save(activity);
    } else {
      console.log(result.error);
      form.setAllTouched();
    }
  }, [form]);

  return (
    <>
      <div className="overflow-auto p-4">
        <Form className="w-full grid grid-cols-2 gap-5 gap-x-8" autoComplete="off">
          <TextInput {...form.props('subject')} type="text" label="Konusu" isRequired />
          <FileInput name="name" type="text" label="Firma Görseli" />

          <DatePicker {...form.props('activitydate')} label="Gerçekleşen Tarih" isRequired />
          <DatePicker {...form.props('activitydueDate')} label="Hatırlatma Tarihi" isRequired />

          <ComboBox {...form.props('type')} label="Türü" defaultItems={activityType} itemKey="id" itemLabel="detail" isRequired />
          <ComboBox {...form.props('status')} label="Son Durum" defaultItems={activityType} itemKey="id" itemLabel="detail" />

          <SelectListBox {...form.props('employeesInCharge')} label="Katılımcı" defaultItems={employeeList} itemKey="id" itemLabel="fullname" isRequired />
        </Form>
      </div>
      <ModalFooter>
        <Button onPress={save}>Kaydet</Button>
      </ModalFooter>
    </>
  );
};
