import * as API from 'api';
import { Box, Button, Card, Tab, TabList, TabPanel, Tabs, Toolbar } from 'components';
import { useForm } from 'hooks';
import { BadgePlus, BadgeX, Save } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Account, AccountForm, AccountFormable, accountToForm } from 'types';

import { useSearchParams } from 'react-router-dom';
import { Aciklamalar } from './components/Aciklamalar';
import { Dosyalar } from './components/Dosyalar';
import { Evraklar } from './components/Evraklar';
import { FirmaBilgi } from './components/FirmaBilgi';
import { FirmaDetay } from './components/FirmaDetay';
import { Firsatlar } from './components/Firsatlar';
import { GeriBildirimler } from './components/GeriBildirimler';
import { Gorevler } from './components/Gorevler';
import { Teklifler } from './components/Teklifler';
import { Toplantilar } from './components/Toplantilar';

export const FirmaKayit = () => {
  const firmaForm = useForm<AccountFormable>(AccountForm, { accountOwner: 1, watchAuto: true });

  const [isUpdate, setIsUpdate] = useState(false);

  const [account, getAccount] = API.ACCOUNT.useFetchById({});

  const [accountAgreements] = API.SOZLUK.ACCOUNT_AGREEMENT.useFetchActive({ init: true });
  const [accountCategory] = API.SOZLUK.ACCOUNT_CATEGORY.useFetchActive({ init: true });
  const [accountGroup] = API.SOZLUK.ACCOUNT_GROUP.useFetchActive({ init: true });
  const [accountIndustry] = API.SOZLUK.ACCOUNT_INDUSTRY.useFetch({ init: true });
  const [accountInformedBy] = API.SOZLUK.ACCOUNT_INFORMED_BY.useFetch({ init: true });
  const [accountOwner] = API.SOZLUK.ACCOUNT_OWNER.useFetchActive({ init: true });
  const [accountPaymentType] = API.SOZLUK.ACCOUNT_PAYMENT_TYPE.useFetchActive({ init: true });
  const [accountStatus] = API.SOZLUK.ACCOUNT_STATUS.useFetch({ init: true });
  const [accountTurnover] = API.SOZLUK.ACCOUNT_TURNOVER.useFetch({ init: true });
  const [accountType] = API.SOZLUK.ACCOUNT_TYPE.useFetch({ init: true });
  const [accountWorkingStatus] = API.SOZLUK.ACCOUNT_WORKING_STATUS.useFetchActive({ init: true });
  const [currency] = API.SOZLUK.CURRENCY.useFetch({ init: true });
  const [taxOffice] = API.SOZLUK.TAX_OFFICE.useFetch({ init: true });
  const [city] = API.SOZLUK.CITY.useFetchCity({ init: true });
  const [shippingStates, getShippingStates] = API.SOZLUK.CITY.useFetchDistrict({});
  const [billingStates, getBillingStates] = API.SOZLUK.CITY.useFetchDistrict({});
  const [country] = API.SOZLUK.COUNTRY.useFetch({ init: true });
  const [department] = API.SOZLUK.DEPARTMENT.useFetch({ init: true });
  const [employeeNumber] = API.SOZLUK.EMPLOYEE_NUMBER.useFetch({ init: true });

  const [employeeList] = API.EMPLOYEE.useFetchActive({ init: true });

  const [hukukList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 39);
  const [istanbulList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 38);
  const [markaList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 5);
  const [patentList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 6);
  const [patentProsedurList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 32);
  const [patentTeknikList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 7);
  const [yenilemeTaksitList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 33);
  const [yonetimList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 14);
  const [yurtDisinaList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 10);
  const [yurtDisindanList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 11);
  const [yurtIciList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 12);
  const [tasarimList] = API.EMPLOYEE.useFetchActiveByDepartmentId({ init: true }, 9);

  const [contactList, getContactList, contactListLoading] = API.CONTACT.useFetchActiveByAccountId({});

  //const [representativeList] = API.ACCOUNT.useFetchActiveRepresentative({ init: true });
  //const [subeList] = API.ACCOUNT.useFetchActiveRepresentative({ init: true });

  const representativeList: Account[] = [];
  const subeList: Account[] = [];

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const accountIdString = searchParams.get('firma');
    if (accountIdString) {
      const accountId = Number(accountIdString);

      getAccount(null, { id: Number(accountIdString) });
      getContactList(null, { accountId });

      setIsUpdate(true);
    }
  }, [searchParams, getAccount, getContactList, setIsUpdate]);

  useEffect(() => {
    if (firmaForm.value.shippingCity) {
      getShippingStates({ id: firmaForm.value.shippingCity });
    }
  }, [firmaForm.value.shippingCity, getShippingStates]);

  useEffect(() => {
    if (firmaForm.value.billingCity) {
      getBillingStates({ id: firmaForm.value.billingCity });
    }
  }, [firmaForm.value.billingCity, getShippingStates]);

  const disabledTabs = useMemo(() => {
    if (isUpdate) {
      return [];
    }
    return ['crm2', 'gorevler2', 'aciklamalar2'];
  }, [isUpdate]);

  function save() {
    const parsed = firmaForm.parse();
    if (parsed.success) {
      console.log(parsed.data);
      toast.success('Firma bilgileri kaydedildi.');
    } else {
      firmaForm.setAllTouched();
      console.log(parsed.error.issues);
      const a: Record<string, string> = {
        unvan: 'Firma Ünvanı',
        firmaTuru: 'Firma Türü',
        firmaDurumu: 'Firma Durumu',
      };
      const e = parsed.error.issues.map((item) => a[item.path[0]]).join(', ');
      toast.error('Gerekli alanları doldurunuz. \n' + e);
    }
  }

  useEffect(() => {
    firmaForm.fill(accountToForm(account));
  }, [account]);

  return (
    <Box className="grid grid-flow-row grid-rows-[46px_1fr] gap-2 overflow-hidden">
      <Toolbar>
        <Button onPress={save}>
          <Save size={18} /> Kaydet
        </Button>
        <Button variant="secondary">
          <BadgePlus size={18} />
          Yeni Kayıt
        </Button>
        <Button variant="danger">
          <BadgeX size={18} /> Dosya Yıl
        </Button>
      </Toolbar>

      <Card className="flex flex-row items-start gap-2 w-full min-h-[600px]">
        <Tabs disabledKeys={disabledTabs}>
          <TabList>
            <Tab id="bilgi" pulse={firmaForm.touchedAndInvalid}>
              Bilgi
            </Tab>
            <Tab id="detay">Detay</Tab>
            <Tab id="crm">CRM</Tab>
            <Tab id="gorevler">Görevler</Tab>
            <Tab id="dosyalar">Dosyalar</Tab>
            <Tab id="evraklar">Evraklar</Tab>
            <Tab id="aciklamalar">Açıklamalar</Tab>
          </TabList>
          <TabPanel id="bilgi">
            <FirmaBilgi
              account={account}
              accountForm={firmaForm}
              accountAgreements={accountAgreements}
              accountGroup={accountGroup}
              accountOwner={accountOwner}
              accountPaymentType={accountPaymentType}
              accountStatus={accountStatus}
              accountType={accountType}
              accountWorkingStatus={accountWorkingStatus}
              representativeList={representativeList}
              subeList={subeList}
              city={city}
              shippingStates={shippingStates}
              country={country}
              department={department}
              contactList={contactList}
              contactListLoading={contactListLoading}
            />
          </TabPanel>
          <TabPanel id="detay">
            <FirmaDetay
              account={account}
              accountForm={firmaForm}
              accountCategory={accountCategory}
              accountIndustry={accountIndustry}
              accountInformedBy={accountInformedBy}
              accountTurnover={accountTurnover}
              taxOffice={taxOffice}
              employeeNumber={employeeNumber}
              currency={currency}
              city={city}
              billingStates={billingStates}
              country={country}
              department={department}
              hukukList={hukukList}
              istanbulList={istanbulList}
              markaList={markaList}
              patentList={patentList}
              patentProsedurList={patentProsedurList}
              patentTeknikList={patentTeknikList}
              yenilemeTaksitList={yenilemeTaksitList}
              yonetimList={yonetimList}
              yurtDisinaList={yurtDisinaList}
              yurtDisindanList={yurtDisindanList}
              yurtIciList={yurtIciList}
              tasarimList={tasarimList}
            />
          </TabPanel>
          <TabPanel id="crm">
            <div className="flex flex-col gap-3">
              <Toplantilar account={account} employeeList={employeeList} />

              <GeriBildirimler account={account} employeeList={employeeList} departmentList={department} contactList={contactList} />

              <Teklifler account={account} employeeList={employeeList} departmentList={department} contactList={contactList} />

              <Firsatlar account={account} employeeList={employeeList} departmentList={department} contactList={contactList} />
            </div>
          </TabPanel>
          <TabPanel id="gorevler">
            <Gorevler account={account} />
          </TabPanel>
          <TabPanel id="dosyalar">
            <Dosyalar account={account} />
          </TabPanel>
          <TabPanel id="evraklar">
            <Evraklar account={account} />
          </TabPanel>
          <TabPanel id="aciklamalar">
            <Aciklamalar account={account} />
          </TabPanel>
        </Tabs>
      </Card>
    </Box>
  );
};

FirmaKayit.path = '/firma-kayit';
FirmaKayit.title = 'Firma Kayıt';
