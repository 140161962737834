import { PopoverProps, Popover as RACPopover, composeRenderProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';

let button = tv({
  base: 'bg-white w-[var(--trigger-width)] border border-slate-300 rounded shadow-xl py-2 duration-200',
  variants: {
    isEntering: {
      true: 'animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 ease-out '
    },
    isExiting: {
      true: 'animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 ease-in'
    }
  }
});

export const Popover = (props: PopoverProps) => {
  return (
    <RACPopover
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        button({ ...renderProps, className })
      )}
    ></RACPopover>
  );
};
