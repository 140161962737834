import * as API from 'api';
import { Accordion, Box, Button, Card, Cell, Column, ComboBox, ComboBoxRemote, Paginator, Row, SeachToolbar, SelectListBox, Table, TableHeader, TextInput } from 'components';
import { evetHayir } from 'constants';
import { useForm, usePageTableList } from 'hooks';
import { XOctagon } from 'lucide-react';
import { useCallback } from 'react';
import { Form, TableBody } from 'react-aria-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Account, AccountSearchForm, AccountSearchFormable } from 'types';

export const FirmaBasitArama = () => {
  const navigate = useNavigate();

  const form = useForm<AccountSearchFormable>(AccountSearchForm, {});

  const [list, getList, loading, clearList] = API.ACCOUNT.useFetchSimpleSearch({});

  const [accountIndustry] = API.SOZLUK.ACCOUNT_INDUSTRY.useFetch({ init: true });
  const [accountGroup] = API.SOZLUK.ACCOUNT_GROUP.useFetchActive({ init: true });
  const [accountWorkingStatus] = API.SOZLUK.ACCOUNT_WORKING_STATUS.useFetchActive({ init: true });
  const [accountCategory] = API.SOZLUK.ACCOUNT_CATEGORY.useFetchActive({ init: true });
  const [accountType] = API.SOZLUK.ACCOUNT_TYPE.useFetch({ init: true });
  const [accountStatus] = API.SOZLUK.ACCOUNT_STATUS.useFetch({ init: true });
  const [accountPaymentType] = API.SOZLUK.ACCOUNT_PAYMENT_TYPE.useFetchActive({ init: true });

  const [country] = API.SOZLUK.COUNTRY.useFetch({ init: true });
  const [city] = API.SOZLUK.CITY.useFetchCity({ init: true });
  const [states, getStates] = API.SOZLUK.CITY.useFetchDistrict({});

  const search = useCallback(() => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      getList(value);
    }
  }, [form, getList]);

  const accountTableList = usePageTableList({
    list,
    loading,
  });

  const onPressAccount = useCallback(
    (account: Account) => {
      navigate({
        pathname: '/firma-kayit',
        search: createSearchParams({
          firma: account.id + '',
        }).toString(),
      });
    },
    [navigate],
  );

  return (
    <Box className="grid grid-flow-row grid-rows-[46px_1fr] gap-2 overflow-hidden">
      <SeachToolbar onSearch={search} clearForm={form.reset} clearList={clearList} />
      <div className="overflow-x-hidden overflow-y-auto flex flex-col gap-2">
        <Accordion header="Arama Kriterleri" offset={16} open>
          <Card className="w-full">
            <Form className="w-full grid grid-cols-4 gap-5 gap-x-8" autoComplete="off">
              <TextInput {...form.props('accountCode')} type="text" label="Firma Kodu" isDisabled />
              <ComboBoxRemote
                {...form.props('accountCode')}
                url="/account/get-list-auto"
                searchKey="accountTitleOrAccountCode"
                itemKey="code"
                itemLabel="title"
                label="Firma Ünvanı"
              />
              <TextInput {...form.props('tpeOwnerNumber')} type="text" label="TPMK Sahip Numarası" />
              <TextInput {...form.props('accountEmail')} type="string" label="Firma E-posta Adresi" />

              <TextInput {...form.props('workingRepresentativeCode')} type="text" label="Aracı Vekil Kodu" />
              <TextInput {...form.props('workingRepresentativeCode')} type="text" label="Aracı Vekil Ünvanı" />
              <ComboBox {...form.props('representative')} label="Vekil Mi?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />

              <TextInput {...form.props('contactName')} type="text" label="Ad" />

              <ComboBox {...form.props('country')} label="Ülke" defaultItems={country} itemKey="id" itemLabel="name" />
              <ComboBox {...form.props('city')} label="Şehir / İl / Bölge" defaultItems={city} itemKey="id" itemLabel="cityName" />
              <ComboBox {...form.props('state')} label="Semt / İlçe" defaultItems={states} itemKey="id" itemLabel="cityName" />

              <TextInput {...form.props('contactSurname')} type="text" label="Soyad" />

              <SelectListBox {...form.props('industryIdList')} name="firmaTuruList" label="Firma Sektörü" defaultItems={accountIndustry} itemKey="id" itemLabel="detail" />
              <div className="row-span-3 flex flex-col gap-4">
                <ComboBox {...form.props('accountGroup')} label="Şirket Grubu" defaultItems={accountGroup} itemKey="id" itemLabel="detail" />
                <ComboBox {...form.props('accountWorkingStatus')} label="Firma Çalışma Durumu" defaultItems={accountWorkingStatus} itemKey="id" itemLabel="detail" />
                <ComboBox {...form.props('accountCategory')} label="Firma Kategorisi" defaultItems={accountCategory} itemKey="id" itemLabel="detail" isRequired />
                <ComboBox {...form.props('accountType')} label="Firma Türü" defaultItems={accountType} itemKey="id" itemLabel="detail" isRequired />
              </div>

              <div className="row-span-3 flex flex-col gap-4">
                <ComboBox {...form.props('accountStatus')} label="Firma Durumu" defaultItems={accountStatus} itemKey="id" itemLabel="detail" isRequired />

                <ComboBox {...form.props('accountPaymentType')} label="Ödeme Şekli" defaultItems={accountPaymentType} itemKey="id" itemLabel="detail" />
                <ComboBox {...form.props('watchAuto')} label="Otomatik Gözlem" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
                <ComboBox {...form.props('deleted')} label="Silinmiş Firmalarda Ara" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
              </div>
            </Form>
          </Card>
        </Accordion>

        <Card>
          <div className="grid gap-1">
            <Table>
              <TableHeader>
                <Column isRowHeader>Kodu</Column>
                <Column>Ünvan</Column>
                <Column>Şirket Grup</Column>
                <Column>Firma Çalışma Durumu</Column>
                <Column>Firma Kategori</Column>
                <Column>Firma Türü</Column>
                <Column>Firma Durumu</Column>
                <Column>Adres</Column>
                <Column>Şehir</Column>
                <Column>Ülke</Column>
                <Column>E-Posta</Column>
                <Column>Sil</Column>
              </TableHeader>

              <TableBody {...accountTableList}>
                {(row) => (
                  <Row key={row.id}>
                    <Cell>
                      <Button variant="text" className="w-full text-theme-600" onPress={() => onPressAccount(row)}>
                        {row.code}
                      </Button>
                    </Cell>
                    <Cell>{row.title}</Cell>
                    <Cell>{row.accountGroup?.detail}</Cell>
                    <Cell>{row.workingStatus?.detail}</Cell>
                    <Cell>{row.accountCategory?.detail}</Cell>
                    <Cell>{row.accountType?.detail}</Cell>
                    <Cell>{row.accountStatus?.detail}</Cell>
                    <Cell>{row.shippingAddress?.addressLine1}</Cell>
                    <Cell>{row.shippingAddress?.city?.cityName}</Cell>
                    <Cell>{row.shippingAddress?.country?.name}</Cell>
                    <Cell>{row.accountEmail}</Cell>
                    <Cell>{row.deleted && <XOctagon className="text-red-500" />}</Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
            <Paginator {...accountTableList} />
          </div>
        </Card>
      </div>
    </Box>
  );
};

FirmaBasitArama.path = '/firma-basit-arama';
FirmaBasitArama.title = 'Firma Basit Arama';
