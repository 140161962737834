import { useFetch } from 'hooks';
import { Opportunity, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<Opportunity[]>('/opportunity/get-opportunity-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<Opportunity[]>('/opportunity/get-active-opportunity-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (opportunity: Opportunity) => request('/opportunity/save', 'POST', opportunity),
  delete: async (opportunity: Opportunity) => request('/opportunity/delete', 'POST', null, { id: opportunity.id }),
};
