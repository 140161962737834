import { composeRenderProps, ToolbarProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Box, BoxProps } from './Box';

let card = tv({
  base: 'bg-white border border-gray-300 rounded-lg shadow-md p-2',
});

export const Card = (props: BoxProps) => {
  return <Box {...props} className={card({ className: props.className })}></Box>;
};
