import * as API from 'api';
import { Accordion, Box, Button, Card, Cell, Column, ComboBox, ComboBoxRemote, Paginator, Row, SeachToolbar, Table, TableHeader, TableToolbar, TextInput } from 'components';
import { evetHayir } from 'constants';
import { useForm, usePageTableList } from 'hooks';
import { CheckCircle, XOctagon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { DialogTrigger, Form, TableBody } from 'react-aria-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Contact, ContactSearchForm, ContactSimpleSearchRequestModel } from 'types';
import { IletisimKayitDialog } from '../firma-kayit/components/IletisimKayit';

export const FirmaIletisimBasitArama = () => {
  const navigate = useNavigate();

  const form = useForm<ContactSimpleSearchRequestModel>(ContactSearchForm, {});

  const [country] = API.SOZLUK.COUNTRY.useFetch({ init: true });
  const [department] = API.SOZLUK.DEPARTMENT.useFetch({ init: true });
  const [city] = API.SOZLUK.CITY.useFetchCity({ init: true });
  const [contactType] = API.SOZLUK.CONTACT_TYPE.useFetch({ init: true });
  const [contactTitle] = API.SOZLUK.CONTACT_TITLE.useFetch({ init: true });

  const [list, getList, loading, clearList] = API.CONTACT.useFetchSimpleSearch({});

  const contactListFlated = useMemo(() => {
    if (list) {
      return list.map((contact) => contact.contactInformation?.map((information) => ({ contact, information })) || []).flat();
    }

    return [];
  }, [list]);

  const tableList = usePageTableList({ list: contactListFlated, loading });

  const search = useCallback(() => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      getList(value);
    }
  }, [form, getList]);

  const onPressAccount = useCallback(
    (contact: Contact) => {
      navigate({
        pathname: '/firma-kayit',
        search: createSearchParams({
          firma: contact.contactAccount?.id + '',
        }).toString(),
      });
    },
    [navigate],
  );

  return (
    <Box className="grid grid-flow-row grid-rows-[46px_1fr] gap-2 overflow-hidden">
      <SeachToolbar onSearch={search} clearForm={form.reset} clearList={clearList} />
      <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
        <Accordion header="Arama Kriterleri" offset={16} open>
          <Card className="w-full">
            <Form className="grid w-full grid-cols-4 gap-5 gap-x-8" autoComplete="off">
              <ComboBoxRemote
                {...form.props('accountId')}
                url="/account/get-list-auto"
                searchKey="accountTitleOrAccountCode"
                itemKey="id"
                itemLabel="title"
                label="Firma Ünvanı"
                className="col-span-2"
              />
              <span className="col-span-2"></span>

              <TextInput {...form.props('contactName')} type="text" label="Ad" />
              <TextInput {...form.props('contactSurname')} type="text" label="Soyad" />
              <span className="col-span-2"></span>

              <TextInput {...form.props('contactDetail')} type="text" label="İletişim Bilgisi" />
              <TextInput {...form.props('extensionNumber')} type="text" label="Dahili" />
              <ComboBox {...form.props('relevantPerson')} label="Firma İlgilisi mi?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            </Form>
          </Card>
        </Accordion>

        <Card>
          <div className="grid gap-1">
            <TableToolbar />
            <Table {...tableList}>
              <TableHeader>
                <Column isRowHeader>No</Column>
                <Column>Ünvan</Column>
                <Column>Ad Soyad</Column>
                <Column>İletişim Tür</Column>
                <Column>İletişim Detay</Column>
                <Column>Yetkili</Column>
                <Column>Firma</Column>
                <Column>Sil</Column>
              </TableHeader>

              <TableBody {...tableList}>
                {(row) => (
                  <Row key={row.information.id}>
                    <Cell>{row.index}</Cell>
                    <Cell>{row.contact.contactTitle?.detail}</Cell>
                    <Cell>
                      <DialogTrigger>
                        <Button variant="text" className="w-full text-theme-600">
                          {row.contact.contactName} {row.contact.contactSurname}
                        </Button>
                        <IletisimKayitDialog
                          isUpdate
                          contact={row.contact}
                          account={row.contact.contactAccount}
                          department={department}
                          country={country}
                          city={city}
                          contactTitle={contactTitle}
                          contactType={contactType}
                        />
                      </DialogTrigger>
                    </Cell>
                    <Cell>{row.information.contactType?.detail}</Cell>
                    <Cell>{row.information.contactTypeDetail}</Cell>
                    <Cell>{row.contact.contactisRelevantPerson && <CheckCircle size={18} />}</Cell>
                    <Cell>
                      <Button variant="text" className="text-theme-600 w-full" onPress={() => onPressAccount(row.contact)}>
                        {row.contact.contactAccount?.title}
                      </Button>
                    </Cell>
                    <Cell>{row.contact.deleted && <XOctagon className="text-red-500" />}</Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
            <Paginator {...tableList} />
          </div>
        </Card>
      </div>
    </Box>
  );
};

FirmaIletisimBasitArama.path = '/firma-iletisim-basit-arama';
FirmaIletisimBasitArama.title = 'Firma İletişim Arama';
