import { useFetch } from 'hooks';
import { Activity, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<Activity[]>('/activity/get-activity-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<Activity[]>('/activity/get-activity-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (activity: Activity) => request('/activity/save', 'POST', activity),
  delete: async (activity: Activity) => request('/activity/delete', 'POST', null, { id: activity.id }),
};
