import * as API from 'api';
import { Accordion, Button, ComboBox, DatePicker, Dialog, FileInput, Modal, ModalFooter, ModalHeader, SelectListBox, TextInput } from 'components';
import { useForm } from 'hooks';
import { useCallback } from 'react';
import { Form } from 'react-aria-components';
import { Account, Contact, Department, Employee, FeedBack, FeedBackForm, FeedBackFormable } from 'types';
import { toModelList } from 'utils';

export interface GeriBildirimKayitProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const GeriBildirimKayitDialog = (props: GeriBildirimKayitProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh]">
        <ModalHeader header="Geri Bildirim Kayıt" />
        <GeriBildirimKayit {...props}></GeriBildirimKayit>
      </Dialog>
    </Modal>
  );
};

export const GeriBildirimKayit = ({ account, employeeList, departmentList, contactList }: GeriBildirimKayitProps) => {
  const form = useForm<FeedBackFormable>(FeedBackForm, {});

  const [feedBackStatus] = API.SOZLUK.FEED_BACK_STATUS.useFetch({ init: true });
  const [feedBackType] = API.SOZLUK.FEED_BACK_TYPE.useFetch({ init: true });
  const [feedBackSubjectType] = API.SOZLUK.FEED_BACK_SUBJECT_TYPE.useFetch({ init: true });

  const save = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      const feedBack: FeedBack = {
        ...value,
        account: account,
        feedBackContact: { id: value.feedBackContact },
        assignedEmployee: { id: value.feedBackStatus },
        feedBackStatus: { id: value.feedBackStatus },
        feedBackType: { id: value.feedBackType },
        feedBackSubjectType: { id: value.feedBackSubjectType },
        feedBackDate: value.feedBackDate?.toString(),
        feedBackRelatedDepartment: toModelList<Department>(value.feedBackRelatedDepartment),

        feedBackResult: { id: value.feedBackResult },
        resultDate: value.resultDate?.toString(),

        deleted: false,
      };

      await API.FEED_BACK.save(feedBack);
    } else {
      console.log(result.error);
      form.setAllTouched();
    }
  }, [form]);

  return (
    <>
      <div className="overflow-auto p-4">
        <Form className="w-full grid grid-cols-2 gap-5 gap-x-8" autoComplete="off">
          <TextInput {...form.props('trackingNumber')} type="text" label="Geri Bildirim Takip No" isDisabled />
          <FileInput name="name" type="text" label="İlgili Dosyalar" />

          <ComboBox {...form.props('assignedEmployee')} label="Geri Bildirimi Giderecek Kişi" defaultItems={employeeList} itemKey="id" itemLabel="fullname" isRequired />
          <DatePicker {...form.props('feedBackDate')} label="Geri Bildirim Tarihi" isRequired />

          <SelectListBox {...form.props('feedBackRelatedDepartment')} label="Bölüm/Birim" defaultItems={departmentList} itemKey="id" itemLabel="detail" />
          <TextInput {...form.props('comment')} type="text" label="Geri Bildirim Açıklaması" textArea isRequired />

          <ComboBox {...form.props('feedBackContact')} label="Geri Bildirim Sahibi" defaultItems={contactList} itemKey="id" itemLabel="contactName" />
          <ComboBox {...form.props('feedBackStatus')} label="Geri Bildirim Durumu" defaultItems={feedBackStatus} itemKey="id" itemLabel="detail" isRequired />

          <ComboBox {...form.props('feedBackType')} label="Geri Bildirim Türü" defaultItems={feedBackType} itemKey="id" itemLabel="detail" />
          <ComboBox {...form.props('feedBackSubjectType')} label="Geri Bildirim Konu Türü" defaultItems={feedBackSubjectType} itemKey="id" itemLabel="detail" />

          <Accordion header="Geri Bildirim Sonucu" className="col-span-2" panelClass="grid grid-cols-2 gap-5 gap-x-8" >
            <ComboBox {...form.props('feedBackResult')} label="Geri Bildirim Sonucu" defaultItems={feedBackType} itemKey="id" itemLabel="detail" />
            <DatePicker {...form.props('resultDate')} label="Sonuç Tarihi" />

            <TextInput {...form.props('resultComment')} type="text" className="col-span-2" label="Sonuç Açıklaması" textArea />
          </Accordion>
        </Form>
      </div>
      <ModalFooter>
        <Button onPress={save}>Kaydet</Button>
      </ModalFooter>
    </>
  );
};
