import { BadgeX, FileText, RotateCcw, Search, Sheet } from 'lucide-react';
import { useCallback } from 'react';
import { Toolbar as AriaToolbar, DialogTrigger, Key, ToolbarProps, composeRenderProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Button } from './Button';
import { DeleteConfirmModal } from './Modal';

let toolbar = tv({
  base: 'bg-white border border-gray-300 rounded shadow p-2 flex flex-row gap-2',
});

export const Toolbar = (props: ToolbarProps) => {
  return <AriaToolbar {...props} className={composeRenderProps(props.className, (className, renderProps) => toolbar({ ...renderProps, className }))}></AriaToolbar>;
};

export interface SeachToolbarProps extends ToolbarProps {
  onSearch?: CallableFunction;
  onClear?: CallableFunction;
  clearForm?: CallableFunction;
  clearList?: CallableFunction;
}

export const SeachToolbar = (props: SeachToolbarProps) => {
  const { onSearch, onClear, clearForm, clearList, ...rest } = props;

  const search = useCallback(() => {
    if (onSearch) {
      onSearch();
    }
  }, [onSearch]);

  const clear = useCallback(() => {
    if (onClear) {
      onClear();
    }
    if (clearForm) {
      clearForm();
    }
    if (clearList) {
      clearList();
    }
  }, [onClear]);

  return (
    <Toolbar {...rest}>
      <Button onPress={search}>
        <Search size={18} /> Ara & Bul
      </Button>
      <Button variant="secondary" onPress={clear}>
        <RotateCcw size={18} />
        Temizle
      </Button>
    </Toolbar>
  );
};

export interface TableToolbarProps extends ToolbarProps {
  selectedKeys?: 'all' | Iterable<Key>;
  items?: CallableFunction;
  del?: CallableFunction;
  excel?: CallableFunction;
  pdf?: CallableFunction;
}

let toolbarTable = tv({
  base: 'bg-gray-100 shadow-sm p-2 py-2 mb-1 ',
});

export const TableToolbar = (props: TableToolbarProps) => {
  const { selectedKeys, del, excel, pdf, ...rest } = props;

  return (
    <Toolbar {...rest} className={composeRenderProps(props.className, (className, renderProps) => toolbarTable({ ...renderProps, className }))}>
      <DialogTrigger>
        <Button variant="danger" className="w-20">
          <BadgeX size={18} /> Sil
        </Button>
        <DeleteConfirmModal header="İletişim Kişi Silme Onayı">Seçili 4 kişiyi silmek istediğinizden emin misiniz?</DeleteConfirmModal>
      </DialogTrigger>
      <Button className="w-20">
        <Sheet size={18} /> Excel
      </Button>
      <Button className="w-20 bg-orange-600 hover:bg-orange-700 pressed:bg-orange-800">
        <FileText size={18} /> PDF
      </Button>
    </Toolbar>
  );
};
