import * as API from 'api';
import { Cell, Column, ComboBox, Paginator, Row, Table, TableHeader } from 'components';
import { useForm, usePageTableList } from 'hooks';
import { useCallback } from 'react';
import { Key, TableBody } from 'react-aria-components';
import account from 'src/api/account';
import { Account, CrmFilesForm, CrmFilesFormable, FileRefanceOptions } from 'types';

export interface DosyalarProps {
  account?: Account;
}

export const Dosyalar = (props: DosyalarProps) => {
  const [list, getList, loading] = API.CRM_FILES.useFetchByAccountIdAndFileRef({});

  const form = useForm<CrmFilesFormable>(CrmFilesForm, {});

  const search = useCallback(
    (fileReferance: Key) => {
      if (props.account && fileReferance) {
        getList(null, { accountId: props.account.id, fileReferance: fileReferance });
      }
    },
    [account],
  );

  const tableList = usePageTableList({
    list,
    loading,
  });

  return (
    <div className="flex flex-col gap-4">
      <div>
        <ComboBox
          {...form.props('fileReferance')}
          label="İlişkili Müşteri Temsilcisi"
          defaultItems={FileRefanceOptions}
          onSelectionChange={search}
          itemKey="id"
          itemLabel="name"
          childKey="children"
          section
        />
      </div>
      <div className="grid gap-1">
        <Table className="w-full">
          <TableHeader>
            <Column isRowHeader>Dosya No</Column>
            <Column>Konu</Column>
            <Column>Son Durum</Column>
            <Column>Dosya Tür</Column>
          </TableHeader>

          <TableBody {...tableList}>
            {(row) => (
              <Row key={row.id}>
                <Cell>{row.fileNumber}</Cell>
                <Cell>{row.firstInfo}</Cell>
                <Cell>{row.secondInfo}</Cell>
                <Cell>{row.fileReferance}</Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        tableList{' '}
      </div>
    </div>
  );
};
