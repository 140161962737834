import { Key, useCallback, useMemo, useState } from 'react';
import { ListBoxItem as AriaListBoxItem, ListBoxItemProps, ComboBoxProps as RACComboBoxProps, composeRenderProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { focusRing } from 'utils';
import { ComboBox } from './ComboBox';
import { ListBox, ListBoxItem } from './ListBox';

interface ListBoxProps<T extends object> extends RACComboBoxProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  itemKey: keyof T;
  itemLabel: keyof T;
  value?: Key[] | null | undefined;
  onChange?: (key: Key[]) => any;
}

export function SelectListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  const [selected, setSelected] = useState<Key | null>('');

  const _onChange = useCallback(
    (key: Key) => {
      setSelected("not_a_value");
      const value = props.value || [];
      if (props.onChange) {
        if (!value.includes(key)) {
          const _value = [...value, key];
          props.onChange(_value);
        }
      }
      setTimeout(() => {
        setSelected('');
      });
    },
    [props.value, props.onChange]
  );

  const selectedItems = useMemo(() => {
    if (props.defaultItems && props.value) {
      return Array.from(props.defaultItems).filter((item) => props.value?.includes(item[props.itemKey] as Key));
    }
    return [];
  }, [props.defaultItems, props.value]);

  return (
    <div className="flex flex-col gap-0.5">
      <ComboBox {...props} value={selected} onChange={_onChange} itemKey={props.itemKey} itemLabel={props.itemLabel}></ComboBox>
      <ListBox aria-label={props.label} className="min-h-48" items={selectedItems}>
        {(item) => <ListBoxItem>{String(item[props.itemLabel])}</ListBoxItem>}
      </ListBox>
    </div>
  );
}
