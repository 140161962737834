import { CalendarDate } from '@internationalized/date';
import { Account } from './Account';
import { Employee } from './Employee';
import { Department, OpportunityStatus, OpportunityType } from './sozluk';
import { z } from 'zod';

export type Opportunity = Partial<{
  id: number;
  status: OpportunityStatus;
  types: OpportunityType[];
  customerRepr: Employee;
  employeeResource: Employee[];
  departmentResource: Department[];
  account: Account;
  realized: boolean;
  dueDate: string;
  // List<OpportunityDocument> documents: string;
  comment: string;
  opportunityTitle: string;
  deleted: boolean;
  version: string;
}>;

export type OpportunityFormable = Partial<{
  opportunityTitle: string;
  status: number;
  realized: boolean;
  customerRepr: number;
  comment: string;
  types: number[];
  dueDate: CalendarDate;
  departmentResource : number[];
  employeeResource : number[];
}>;

export const OpportunityForm = z.object({
  opportunityTitle: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  status: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  realized: z.boolean(),
  customerRepr: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  comment: z.string(),
  types: z.array(z.number()).nullish(),
  dueDate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  departmentResource: z.array(z.number()).nullish(),
  employeeResource: z.array(z.number()).nullish(),
});