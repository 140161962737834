export const evetHayir = [
  { id: true, name: 'Evet' },
  { id: false, name: 'Hayır' },
];

export const aktifPasif = [
  { id: true, name: 'Aktif' },
  { id: false, name: 'Pasif' },
];


export const days = Array.from({length :31}).map((_,index) => ({
  id: index + 1,
  name: (index + 1).toString(),
}));