import * as API from 'api';
import { Button, Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { BadgePlus } from 'lucide-react';
import { useMemo } from 'react';
import { DialogTrigger, TableBody } from 'react-aria-components';
import { Account, City, Contact, Country, Department } from 'types';
import { IletisimKayitDialog } from './IletisimKayit';

export interface IletisimKisilerProps {
  account?: Account;
  contactList?: Contact[];
  department?: Department[];
  country?: Country[];
  city?: City[];
  contactListLoading?: boolean;
}

export const IletisimKisiler = (props: IletisimKisilerProps) => {
  const { account, contactList, department, country, city, contactListLoading } = props;

  const [contactType] = API.SOZLUK.CONTACT_TYPE.useFetch({ init: true });
  const [contactTitle] = API.SOZLUK.CONTACT_TITLE.useFetch({ init: true });

  const contactListFlated = useMemo(() => {
    if (contactList) {
      return contactList.map((contact) => contact.contactInformation?.map((information) => ({ contact, information })) || []).flat();
    }

    return [];
  }, [contactList]);

  const contactTableList = usePageTableList({ list: contactListFlated, loading: contactListLoading });

  return (
    <>
      <div className="mb-2">
        <DialogTrigger>
          <Button variant="secondary">
            <BadgePlus size={18} />
            Ekle
          </Button>
          <IletisimKayitDialog account={account} department={department} country={country} city={city} contactTitle={contactTitle} contactType={contactType} />
        </DialogTrigger>
      </div>
      <div className="grid gap-1">
        <Table className="w-full">
          <TableHeader>
            <Column isRowHeader>No</Column>
            <Column>Görev</Column>
            <Column>Ad Soyad</Column>
            <Column>İletişim Tür</Column>
            <Column>İletişim Detay</Column>
            <Column>Dahili</Column>
            <Column>Açıklama</Column>
            <Column>Apiz</Column>
          </TableHeader>

          <TableBody {...contactTableList}>
            {(row) => (
              <Row id={row.information.id} key={row.information.id}>
                <Cell>{row.index + 1}</Cell>
                <Cell>{row.contact.contactJob}</Cell>
                <Cell>
                  <DialogTrigger>
                    <Button variant="text" className="w-full text-theme-600">
                      {row.contact.contactName} {row.contact.contactSurname}
                    </Button>
                    <IletisimKayitDialog
                      isUpdate
                      contact={row.contact}
                      account={account}
                      department={department}
                      country={country}
                      city={city}
                      contactTitle={contactTitle}
                      contactType={contactType}
                    />
                  </DialogTrigger>
                </Cell>
                <Cell>{row.information.contactType?.detail}</Cell>
                <Cell>{row.information.contactTypeDetail}</Cell>
                <Cell>{row.information.extensionNumber}</Cell>
                <Cell>{row.contact.contactComment}</Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        <Paginator {...contactTableList} />
      </div>
    </>
  );
};
