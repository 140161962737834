import { Accordion, Button, ComboBox, SelectListBox, TextInput } from 'components';
import { days, evetHayir } from 'constants';
import { ClipboardPaste } from 'lucide-react';
import { Form } from 'react-aria-components';
import {
  Account,
  AccountCategory,
  AccountFormable,
  AccountIndustry,
  AccountInformedBy,
  AccountTurnover,
  City,
  Country,
  Currency,
  Department,
  Employee,
  EmployeeNumber,
  FormHook,
  TaxOffice
} from 'types';

export interface FirmaDetayProps {
  accountForm: FormHook<AccountFormable>;
  account?: Account;
  accountCategory?: AccountCategory[];
  accountIndustry?: AccountIndustry[];
  accountInformedBy?: AccountInformedBy[];
  accountTurnover?: AccountTurnover[];
  taxOffice?: TaxOffice[];
  employeeNumber?: EmployeeNumber[];
  currency?: Currency[];
  city?: City[];
  billingStates?: City[];
  country?: Country[];
  department?: Department[];

  hukukList?: Employee[];
  istanbulList?: Employee[];
  markaList?: Employee[];
  patentList?: Employee[];
  patentProsedurList?: Employee[];
  patentTeknikList?: Employee[];
  yenilemeTaksitList?: Employee[];
  yonetimList?: Employee[];
  yurtDisinaList?: Employee[];
  yurtDisindanList?: Employee[];
  yurtIciList?: Employee[];
  tasarimList?: Employee[];
}

export const FirmaDetay = (props: FirmaDetayProps) => {
  const {
    account,
    accountForm,
    accountCategory,
    accountIndustry,
    accountInformedBy,
    accountTurnover,
    taxOffice,
    employeeNumber,
    currency,
    city,
    billingStates,
    country,
    department,
    hukukList,
    istanbulList,
    markaList,
    patentList,
    patentProsedurList,
    patentTeknikList,
    yenilemeTaksitList,
    yonetimList,
    yurtDisinaList,
    yurtDisindanList,
    yurtIciList,
    tasarimList,
  } = props;

  return (
    <Form className="w-full grid grid-cols-3 gap-5 gap-x-8" autoComplete="off">
      <div className="bg-slate-200 text-slate-700 border border-slate-300 w-full rounded shadow px-2 py-1.5 text-base font-semibold text-center col-span-3 flex items-center justify-between">
        <span className="flex-1 text-center">Fatura Bilgileri</span>

        <Button
          className="self-center w-max h-10"
          onPress={() => {
            accountForm.patch({
              billingAddressLine1: accountForm.value.shippingAddressLine1,
              billingAddressLine2: accountForm.value.shippingAddressLine2,
              billingCountry: accountForm.value.shippingCountry,
              billingCity: accountForm.value.shippingCity,
              billingState: accountForm.value.shippingState,
              billingZipCode: accountForm.value.shippingZipCode,
            });
          }}
        >
          <ClipboardPaste size={18} /> Firma Adresinden Kopyala
        </Button>
      </div>
      <div className="flex flex-col min-h-64">
        <TextInput {...accountForm.props('billingAddressLine1')} name="name" type="text" label="Açık Adres" textArea />
        <TextInput {...accountForm.props('billingAddressLine2')} name="name" type="text" label="Adres Kısım 2" textArea />
      </div>
      <div className="col-span-2 grid grid-cols-2  gap-2 gap-x-8 grid-rows-[min-content_min-content] ">
        <ComboBox {...accountForm.props('billingCountry')} label="Ülke" defaultItems={country} itemKey="id" itemLabel="name" />
        <ComboBox {...accountForm.props('billingCity')} label="Şehir / İl / Bölge" defaultItems={city} itemKey="id" itemLabel="cityName" />
        <ComboBox {...accountForm.props('billingState')} label="Semt / İlçe" defaultItems={billingStates} itemKey="id" itemLabel="cityName" />
        <TextInput {...accountForm.props('billingZipCode')} type="text" label="Posta Kodu" />

        <ComboBox name="name" label="Vergi Dairesi" defaultItems={taxOffice} itemKey="id" itemLabel="detail" />
        <TextInput name="name" type="text" label="Vergi Kimlik No" />
        <TextInput name="name" type="text" label="TC Kimlik No" />
      </div>

      <Accordion className="col-span-3" header="Firma İletişim Bilgileri" open>
        <div className="w-full grid grid-cols-3 gap-5 gap-x-8">
          <div className="flex flex-col gap-5">
            <ComboBox {...accountForm.props('accountCategory')} label="Firma Kategorisi" defaultItems={accountCategory} itemKey="id" itemLabel="detail" isRequired />
            <ComboBox {...accountForm.props('employeeNumber')} label="Firma Çalışan Sayısı" defaultItems={employeeNumber} itemKey="id" itemLabel="detail" isRequired />
          </div>

          <SelectListBox {...accountForm.props('industries')} name="firmaTuruList" label="Firma Sektörü" defaultItems={accountIndustry} itemKey="id" itemLabel="detail" />

          <div className="flex flex-col gap-5">
            <ComboBox {...accountForm.props('accountTurnover')} label="Firma Cirosu" defaultItems={accountTurnover} itemKey="id" itemLabel="detail" isRequired />
            <ComboBox {...accountForm.props('accountTurnoverCurrency')} label="Para Birimi" defaultItems={currency} itemKey="id" itemLabel="detail" isRequired />
          </div>

          <TextInput {...accountForm.props('bulkTransactionCost')} type="string" label="Toplu Hizmet Fatura Tutarı" />
          <ComboBox {...accountForm.props('bulkTransactionDay')} label="Toplu Hizmet Fatura Günü" defaultItems={days} itemKey="id" itemLabel="name" isRequired />
          <ComboBox {...accountForm.props('maxDayForTransaction')} label="Fatura Uyarı Günü" defaultItems={days} itemKey="id" itemLabel="name" isRequired />

          <ComboBox {...accountForm.props('hasPriceList')} label="Özel Tarifesi Var mı?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
          <ComboBox {...accountForm.props('priceDiscount')} label="%10'luk İndirim Tarifesi Var mı?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
          <ComboBox {...accountForm.props('inEmailList')} label="Toplu E-posta Bildirimi" defaultItems={evetHayir} itemKey="id" itemLabel="name" />

          <TextInput {...accountForm.props('accountInternetAddress')} type="string" label="Firma İndernet Adresi" />
          <TextInput {...accountForm.props('accountEmail')} type="string" label="Firma E-posta Adresi" />
          <TextInput {...accountForm.props('faxNumber')} type="string" label="Firma Faks Numarası" />

          <SelectListBox {...accountForm.props('departmentsConcerned')} label="İlgili Olduğu Bölüm / Birim" defaultItems={department} itemKey="id" itemLabel="detail" />

          <SelectListBox {...accountForm.props('customerRepresentative')} label="İlişkilendiren Müşteri Temsilcisi" defaultItems={accountIndustry} itemKey="id" itemLabel="detail" />

          <span></span>

          <TextInput {...accountForm.props('telNumber')} type="string" label="Firma Telefon Numarası" />
          <ComboBox {...accountForm.props('informedBy')} label="Bizden Haberdar Olma Yolu" defaultItems={accountInformedBy} itemKey="id" itemLabel="detail" />

          <span></span>

          <SelectListBox {...accountForm.props('dept39Responsible')} label="Hukuk Yetkilisi" defaultItems={hukukList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept38Responsible')} label="İstanbul Yetkilisi" defaultItems={istanbulList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept5Responsible')} label="Marka Yetkilisi" defaultItems={markaList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept6Responsible')} label="Patent Yetkilisi" defaultItems={patentList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept32Responsible')} label="Patent Presedür Yetkilisi" defaultItems={patentProsedurList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept7Responsible')} label="Patent Teknik Yetkilisi" defaultItems={patentTeknikList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept33Responsible')} label="Yenileme ve Taksit Yetkilisi" defaultItems={yenilemeTaksitList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept14Responsible')} name="firmaTuruList" label="Yönetim Yetkilisi" defaultItems={yonetimList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept10Responsible')} label="Yurt Dışına Marka Yetkilisi" defaultItems={yurtDisinaList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept11Responsible')} label="Yurt Dışından Marka Yetkilisi" defaultItems={yurtDisindanList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept12Responsible')} label="Yurt İçi Marka Yetkilisi" defaultItems={yurtIciList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...accountForm.props('dept9Responsible')} label="Tasarım Yetkilisi" defaultItems={tasarimList} itemKey="id" itemLabel="fullname" />
        </div>
      </Accordion>
    </Form>
  );
};
