import { HTMLAttributes } from 'react';
import { SideMenu } from './SideMenu';
import { Link } from 'react-aria-components';
import { Gift, LogOutIcon } from 'lucide-react';
import { Button } from './Button';
import { menu } from '../constants';

export const Sidebar = () => {
  return (
    <div className="w-full bg-white h-full flex flex-col border-r border-r-gray-200 shadow-lg ">
      <Link href="/" className="outline-0 w-full text-center flex items-center justify-center my-4">
        <Gift size={48} className="text-theme-600" />
      </Link>
      <hr className="py-2 border-gray-200" />
      <SideMenu className="flex-1" root open item={menu} level={0} />
      <hr />
      <div className="py-4 pb-9 flex flex-col items-center gap-2">
        <span>🇺🇸  31,1169</span>
        <span>🇪🇺  33,7558</span>
        <span>🇨🇭  3,0113</span>
        <Button variant="secondary" className="w-32 text-base justify-center">
          <LogOutIcon />
          Çıkış
        </Button>
      </div>
    </div>
  );
};
