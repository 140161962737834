import { Department } from './sozluk';

export type FeeListRTFTemplate = Partial<{
  id: number;
  fileName: string;
}>;

export type FeeListDefinition = Partial<{
  id: number;
  name: string;
  definition: string;
  deleted: boolean;
  base: boolean;
}>;

export type FeeList = Partial<{
  id: number;
  description: string;
  deleted: boolean;
  billed: boolean;

  templateFile: FeeListRTFTemplate;

  definition: FeeListDefinition;

  department: Department;

  taskProcess: boolean;
  reminderDay: string;
  taskDuration: number;
  taskDurationUrgent: number;
}>;
