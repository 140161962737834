import { useFetch } from 'hooks';
import { AccountAgreement, AccountCategory, AccountGroup, AccountIndustry, AccountInformedBy, AccountOwner, AccountPaymentType, AccountStatus, AccountTurnover, AccountType, AccountWorkingStatus, City, ContactTitle, ContactType, Country, Currency, Department, EmployeeNumber, PapersType, ProposalCostDiscountType, ProposalType, TaskAwaitingReasonType, TaskAwaitingReminderType, TaskProcessType, TaskStatus, TaxOffice, UseFetchOptions } from 'types';

export const ACCOUNT_AGREEMENT = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountAgreement[]>('/account-agreement/get-active-list', { method: 'POST', ...config }),
};

export const ACCOUNT_CATEGORY = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountCategory[]>('/account-category/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_GROUP = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountGroup[]>('/account-group/get-active-list', { method: 'POST', ...config }),
};

export const ACCOUNT_INDUSTRY = {
  useFetch: (config: UseFetchOptions) => useFetch<AccountIndustry[]>('/account-industry/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_INFORMED_BY = {
  useFetch: (config: UseFetchOptions) => useFetch<AccountInformedBy[]>('/account-informed-by/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_OWNER = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountOwner[]>('/account-owner/get-active-list', { method: 'POST', ...config }),
};

export const ACCOUNT_PAYMENT_TYPE = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountPaymentType[]>('/account-payment-type/get-active-list', { method: 'POST', ...config }),
};

export const ACCOUNT_STATUS = {
  useFetch: (config: UseFetchOptions) => useFetch<AccountStatus[]>('/account-status/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_TURNOVER = {
  useFetch: (config: UseFetchOptions) => useFetch<AccountTurnover[]>('/account-turnover/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<AccountType[]>('/account-type/get-list', { method: 'POST', ...config }),
};

export const ACCOUNT_WORKING_STATUS = {
  useFetchActive: (config: UseFetchOptions) => useFetch<AccountWorkingStatus[]>('/account-working-status/get-active-list', { method: 'POST', ...config }),
};

export const CURRENCY = {
  useFetch: (config: UseFetchOptions) => useFetch<Currency[]>('/currency/get-list', { method: 'POST', ...config }),
};

export const TAX_OFFICE = {
  useFetch: (config: UseFetchOptions) => useFetch<TaxOffice[]>('/tax-office/get-list', { method: 'POST', ...config }),
};

export const CITY = {
  useFetchCity: (config: UseFetchOptions) => useFetch<City[]>('/city/get-city-list', { method: 'POST', ...config }),
  useFetchDistrict: (config: UseFetchOptions) => useFetch<City[]>('/city/get-district-list', { method: 'POST', ...config }),
};

export const COUNTRY = {
  useFetch: (config: UseFetchOptions) => useFetch<Country[]>('/country/get-list', { method: 'POST', ...config }),
};

export const DEPARTMENT = {
  useFetch: (config: UseFetchOptions) => useFetch<Department[]>('/department/get-list', { method: 'POST', ...config }),
};

export const EMPLOYEE_NUMBER = {
  useFetch: (config: UseFetchOptions) => useFetch<EmployeeNumber[]>('/employee-number/get-list', { method: 'POST', ...config }),
};

export const CONTACT_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ContactType[]>('/contact-type/get-list', { method: 'POST', ...config }),
};

export const CONTACT_TITLE = {
  useFetch: (config: UseFetchOptions) => useFetch<ContactTitle[]>('/contact-title/get-list', { method: 'POST', ...config }),
};

export const TASK_STATUS = {
  useFetch: (config: UseFetchOptions) => useFetch<TaskStatus[]>('/task-status/get-list', { method: 'POST', ...config }),
};

export const TASK_PROCESS_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<TaskProcessType[]>('/task-process-type/get-list', { method: 'POST', ...config }),
};

export const TASK_AWAITING_REMINDER_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<TaskAwaitingReminderType[]>('/task-awaiting-reminder-type/get-list', { method: 'POST', ...config }),
};

export const TASK_AWAITING_REASON_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<TaskAwaitingReasonType[]>('/task-awaiting-reason-type/get-list', { method: 'POST', ...config }),
};

export const PROPOSAL_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/proposal-type/get-active-list', { method: 'POST', ...config }),
};

export const PROPOSAL_COST_DISCOUNT_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalCostDiscountType[]>('/proposal-cost-discount/get-list', { method: 'POST', ...config }),
};

export const OPPORTUNITY_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/proposal-type/get-list', { method: 'POST', ...config }),
};

export const OPPORTUNITY_STATUS = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/proposal-status/get-list', { method: 'POST', ...config }),
};

export const FEED_BACK_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/feed-back-type/get-list', { method: 'POST', ...config }),
};

export const FEED_BACK_SUBJECT_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/feed-back-subject-type/get-list', { method: 'POST', ...config }),
};

export const FEED_BACK_STATUS= {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/feed-back-status-type/get-list', { method: 'POST', ...config }),
};

export const FEED_BACK_RESULT = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/feed-back-result/get-list', { method: 'POST', ...config }),
};

export const ACTIVITY_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<ProposalType[]>('/activity-type/get-activity-type-list', { method: 'POST', ...config }),
};

export const PAPERS_TYPE = {
  useFetch: (config: UseFetchOptions) => useFetch<PapersType[]>('/papers-type/get-list', { method: 'POST', ...config }),
};