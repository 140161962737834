import { AlertTriangle, BadgeX } from 'lucide-react';
import { HTMLAttributes } from 'react';
import { Heading, ModalOverlay, ModalOverlayProps, Modal as RACModal } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Button } from './Button';
import { Dialog } from './Dialog';

const overlayStyles = tv({
  base: 'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-black/[35%] flex items-center justify-center p-4 text-center backdrop-blur-none ',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in',
    },
  },
});

const modalStyles = tv({
  base: 'max-h-full rounded-lg bg-white text-left align-middle text-slate-700 shadow-2xl bg-clip-padding border border-black/10',
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 ease-out duration-200',
    },
    isExiting: {
      true: 'animate-out zoom-out-95 ease-in duration-200',
    },
  },
});

export function Modal(props: ModalOverlayProps) {
  return (
    <ModalOverlay {...props} className={overlayStyles}>
      <RACModal {...props} className={modalStyles} />
    </ModalOverlay>
  );
}

const modalHeader = tv({
  base: 'flex bg-gray-100 rounded-t-lg p-2 px-4 pt-3 border-b border-gray-300',
});

export interface ModalHeaderProps extends HTMLAttributes<HTMLElement> {
  header: string;
}

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <div {...props} className={modalHeader({ className: props.className })}>
      <Heading className="text-lg font-semibold tracking-wide" slot="title">
        {props.header}
      </Heading>
    </div>
  );
}

const modalFooter = tv({
  base: 'flex justify-end gap-4 bg-gray-100 rounded-b-lg px-8 p-2.5 border-t border-gray-300',
});

export function ModalFooter(props: HTMLAttributes<HTMLElement>) {
  return <div {...props} className={modalFooter({ className: props.className })}></div>;
}

export interface ConfirmModalProps extends HTMLAttributes<HTMLElement> {
  header: string;
}

export function DeleteConfirmModal(props: ConfirmModalProps) {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[420px] max-h-[90vh]">
        {({ close }) => (
          <>
            <div className="px-8 py-8 flex flex-col items-center gap-2 text-lg text-center">
              <AlertTriangle className="stroke-red-800" size={48} />
              {props.children}
            </div>
            <ModalFooter>
              <Button variant="dark" className="min-w-20" onPress={close}>
                İptal
              </Button>
              <Button variant="danger" className="min-w-20">
                <BadgeX size={18} />
                Sil
              </Button>
            </ModalFooter>
          </>
        )}
      </Dialog>
    </Modal>
  );
}
