import { ChevronDown, ChevronLeft, ChevronRight, XCircle } from 'lucide-react';
import React, { HTMLAttributes, Key, useCallback, useMemo } from 'react';
import {
  Button,
  ComboBoxStateContext,
  FieldError,
  Group,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  Text,
  DateValue,
  Calendar,
  Heading,
  CalendarGrid,
  CalendarCell,
  DateInput,
  DateSegment,
  DatePickerStateContext,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Popover } from './Popover';

export interface DatePickerProps<T extends DateValue> extends AriaDatePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  labelClass?: string;
}

let textInput = tv({
  slots: {
    base: 'flex flex-col items-start gap-0.5',
    label: 'font-semibold  pl-0.5',
    group:
      'relative w-full flex items-center bg-white border border-slate-300 rounded pr-1 outline-none has-[[data-focus-visible]]:outline-2 has-[[data-focus-visible]]:outline-theme-300 has-[[data-focus-visible]]:outline-offset-1 ',
    input: 'w-full flex flex-1 bg-transparent outline-0 p-1.5 px-2 pointer-events-none ',
    description: 'text-xs pl-0.5',
    error: 'text-xs pl-0.5 text-red-600',
    calendar: 'px-4 py-0 min-w-48 ',
  },
  variants: {
    isInvalid: {
      true: {
        group: 'border-red-600',
      },
    },
    isDisabled: {
      true: {
        label: 'text-slate-300',
        group: 'bg-slate-100 border-slate-200',
      },
    },
    isReadOnly: {
      true: {
        group: 'bg-slate-50 border-slate-200',
      },
    },
  },
});

const cell = tv({
  base: 'w-7 h-7 text-sm cursor-default rounded-full flex items-center justify-center select-none outline-0 transition-colors duration-300',
  variants: {
    isSelected: {
      false: 'text-zinc-900 dark:text-zinc-200 hover:bg-theme-100 pressed:bg-theme-200',
      true: 'bg-theme-600 invalid:bg-red-600 text-white',
    },
    isDisabled: {
      true: 'text-gray-300 dark:text-zinc-600',
    },
  },
});

export const DatePicker = <T extends DateValue>(props: DatePickerProps<T>) => {
  const { base, label, group, input, description, error, calendar } = textInput({
    isInvalid: props.isInvalid,
    isDisabled: props.isDisabled,
    isReadOnly: props.isReadOnly,
  });

  const _value = useMemo(() => {
    if (props.value) {
      return props.value;
    }
    return null;
  }, [props.value]);

  return (
    <AriaDatePicker {...props} value={_value} validationBehavior="aria" className={base()}>
      <Label className={label({className: props.labelClass})}>
        {props.label} {props.isRequired && '*'}
      </Label>
      <Group className={group()}>
        <DatePickerFocusButton className="flex-1 outline-none outline-0" isReadOnly={props.isReadOnly} isDisabled={props.isDisabled}>
          <DateInput className={input()}>{(segment) => <DateSegment segment={{ ...segment, isEditable: false }} />}</DateInput>
        </DatePickerFocusButton>
        {Boolean(props.value) && <DatePickerClearButton />}
        <Button className="outline-none outline-0">
          <ChevronDown className="outline-none outline-0" />
        </Button>
      </Group>
      {props.description && (
        <Text className={description()} slot="description">
          {props.description}
        </Text>
      )}
      <FieldError className={error()}>{props.errorMessage}</FieldError>
      <Popover>
        <Calendar className={calendar()}>
          <header className="flex items-center gap-1 pb-4 px-1 w-full">
            <Button slot="previous" className="outline-0">
              <ChevronLeft className="outline-0" />
            </Button>
            <Heading className="flex-1 font-semibold text-xl text-center mx-2 text-zinc-900" />
            <Button slot="next" className="outline-0">
              <ChevronRight className="outline-0" />
            </Button>
          </header>
          <CalendarGrid>{(date) => <CalendarCell date={date} className={cell} />}</CalendarGrid>
        </Calendar>
      </Popover>
    </AriaDatePicker>
  );
};

const DatePickerClearButton = () => {
  let state = React.useContext(DatePickerStateContext);

  return (
    <Button slot={null} className="outline-none absolute right-[26px] " aria-label="Clear" onPress={() => state?.setValue(null)}>
      <XCircle className="stroke-slate-500" size={20} />
    </Button>
  );
};

export interface DatePickerFocusButtonProps extends HTMLAttributes<HTMLElement> {
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const DatePickerFocusButton = (props: DatePickerFocusButtonProps) => {
  let state = React.useContext(DatePickerStateContext);

  return (
    <Button
      slot={null}
      className={props.className}
      onPress={() => {
        if (!(props.isReadOnly || props.isDisabled)) {
          state?.toggle();
        }
      }}
    >
      {props.children}
    </Button>
  );
};
