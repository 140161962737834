import React, { ReactNode } from 'react';
import {
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  FieldError,
  Label,
  RadioProps,
  Text,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

export interface RadioGroupProps extends Omit<AriaRadioGroupProps, 'children'> {
  label?: string;
  children?: ReactNode;
  description?: string;
  errorMessage?: string;
}

let radioGroup = tv({
  base: 'group flex flex-col gap-2',
  slots: {
    base: 'group flex flex-col gap-2',
    label: 'font-semibold  pl-0.5',
    description: 'text-xs pl-0.5',
    error: 'text-xs pl-0.5 text-red-600',
  },
  variants: {
    isDisabled: {
      true: {
        label: 'text-slate-300',
      },
    },
  },
});

export function RadioGroup(props: RadioGroupProps) {
  const { base, label, description, error } = radioGroup({
    isDisabled : props.isDisabled
  });

  return (
    <AriaRadioGroup {...props}  className={composeRenderProps(props.className, (className, renderProps) =>
      base({ ...renderProps, className })
    )}>
      <Label className={label()}>
        {props.label} {props.isRequired && '*'}
      </Label>
      <div className="flex group-orientation-vertical:flex-col gap-2 group-orientation-horizontal:gap-4">
        {props.children}
      </div>
      {props.description && (
        <Text className={description()} slot="description">
          {props.description}
        </Text>
      )}
      <FieldError className={error()}>{props.errorMessage}</FieldError>
    </AriaRadioGroup>
  );
}

const radioContainer = tv({
  base: 'flex gap-2 items-center group text-slate-800 disabled:text-slate-300  text-sm transition',
});

const radio = tv({
  base: 'w-5 h-5 rounded-full border-2 bg-white  transition-all',
  variants: {
    isSelected: {
      false: 'border-slate-400  group-pressed:border-slate-500 ',
      true: 'border-7 border-theme-400 group-pressed:border-theme-600',
    },
    isInvalid: {
      true: 'border-red-600 group-pressed:border-red-800',
    },
    isDisabled: {
      true: 'border-slate-200',
    },
  },
});

export function Radio(props: RadioProps) {
  return (
    <AriaRadio
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        radioContainer({ ...renderProps, className })
      )}
    >
      {(renderProps) => (
        <>
          <div className={radio(renderProps)} />
          {props.children}
        </>
      )}
    </AriaRadio>
  );
}
