import { Check, Minus } from 'lucide-react';
import { Key } from 'react';
import {
  composeRenderProps,
  Checkbox as AriaCheckbox,
  CheckboxProps as AriaCheckboxProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

export interface CheckboxProps extends Omit<AriaCheckboxProps, 'value'> {
  value?: boolean | undefined;
  formValue?: string;
  label?: string;
}

const checkbox = tv({
  base: 'flex gap-2 items-center justify-center group text-sm transition',
  variants: {
    isDisabled: {
      false: 'text-gray-800',
      true: 'text-gray-300',
    },
  },
});

const box = tv({
  base: 'w-5 h-5 flex-shrink-0 rounded flex items-center justify-center border-2 transition',
  variants: {
    isSelected: {
      false: 'bg-white border border-slate-400  group-pressed:border-slate-700',
      true: 'bg-theme-400 border-theme-500 group-pressed:border-theme-600',
    },
    isInvalid: {
      true: ' border-red-600 group-pressed:border-red-800',
    },
    isDisabled: {
      true: 'bg-slate-200 border-slate-300',
    },
  },
});

export const Checkbox = (props: CheckboxProps) => {
  const _props: AriaCheckboxProps = {
    ...props,
    isSelected: props.value,
    value: props.formValue,
  };

  return (
    <AriaCheckbox
      {..._props}
      aria-label="checkbox"
      className={composeRenderProps(props.className, (className, renderProps) =>
        checkbox({ ...renderProps, className })
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <>
          <div className={box({ isSelected: isSelected || isIndeterminate, ...renderProps })}>
            {isIndeterminate ? (
              <Minus aria-hidden className="w-4 h-4 text-white group-disabled:text-gray-400" />
            ) : isSelected ? (
              <Check aria-hidden className="w-4 h-4 text-white group-disabled:text-gray-400" />
            ) : null}
          </div>
          {props.children}  {props.isRequired && '*'}
        </>
      )}
    </AriaCheckbox>
  );
};
