import { Department } from "./sozluk";

export type Employee = Partial<{
  id: number;
  name: string;
  surname: string;
  email: string;
  password: string;
  userDepartment: Department;
  // WorkGroup workGroup: string;
  startingOfEmployment: string;
  expiry: string;
  lastLogin: string;
  customerRepresentative: boolean;
  deleted: boolean;
  level: number;
  userName: string;
  unixUserName: string;
  // List<Role> role: string;
  firstPage: string;
  registrationNumber: string;
  feelistDepartment: Department[];
  fullname : string;
}>;
