import { HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { Button } from './Button';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';

export interface SideMenuProps extends HTMLAttributes<HTMLElement> {
  item: any;
  level: number;
  root?: boolean;
  open?: boolean;
}

let sideMenuButton = tv({
  base: 'w-full justify-start text-left rounded-none h-9 focus-visible:outline-0',
  variants: {
    active : {
      true : 'text-theme-600 bg-theme-100 hover:bg-theme-200 pressed:bg-theme-300'
    }
  }
});

let sideMenuChildren = tv({
  base: 'flex flex-col overflow-hidden transition-all select-none',
  variants: {
    level : {
      1 : 'bg-gray-100'
    }
  }
});

export const SideMenu = (props: SideMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(Boolean(props.open));
  const ref = useRef<HTMLDivElement>(null);

  const onPress = useCallback(() => {
    if(props.item.children) {
      setOpen(!open)
    } else {
      navigate(props.item.path)
    }
  }, [props.item, open, setOpen, navigate ])

  const height = useMemo(() => {
    if (open) {
      return ref.current?.scrollHeight;
    }

    return 0;
  }, [ref, open]);

  const active = useMemo(() => {
    return props.item.path === location.pathname
  }, [props.item, location.pathname])

  return (
    <div className={props.className} >
      {!props.root && <Button style={{ paddingLeft: props.level * 16 }} className={sideMenuButton({active})}  variant="text" onPress={onPress}>{props.item.title}</Button>}
      {props.item.children && (
        <div ref={ref} className={sideMenuChildren({level : props.level as 1})} style={{ height }}>
          {props.item.children.map((item: any) => (
            <SideMenu key={item.path} item={item} level={props.level + 1} />
          ))}
        </div>
      )}
    </div>
  );
};
