import * as API from 'api';
import { Accordion, Button, ComboBox, DatePicker, Dialog, FileInput, Modal, ModalFooter, ModalHeader, SelectListBox, TextInput } from 'components';
import { aktifPasif, evetHayir } from 'constants';
import { useForm } from 'hooks';
import { useCallback } from 'react';
import { Form } from 'react-aria-components';
import { Account, Contact, Department, Employee, Proposal, ProposalForm, ProposalFormable, ProposalType } from 'types';
import { toModelList } from 'utils';

export interface TeklifKayitProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const TeklifKayitDialog = (props: TeklifKayitProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh]">
        <ModalHeader header="Toplantı Kayıt" />
        <TeklifKayit {...props}></TeklifKayit>
      </Dialog>
    </Modal>
  );
};

export const TeklifKayit = ({ account, employeeList, departmentList, contactList }: TeklifKayitProps) => {
  const form = useForm<ProposalFormable>(ProposalForm, {});

  const [proposalCostDiscountType] = API.SOZLUK.PROPOSAL_COST_DISCOUNT_TYPE.useFetch({ init: true });
  const [proposalType] = API.SOZLUK.PROPOSAL_TYPE.useFetch({ init: true });

  const save = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      const proposal: Proposal = {
        ...value,
        proposalAccount: account,
        proposalDate: value.proposalDate?.toString(),
        proposalDateOfValidity: value.proposalDateOfValidity?.toString(),
        proposalCostDiscount: { id: value.proposalCostDiscount },
        proposer: { id: value.proposer },
        proposalDepartment: toModelList<Department>(value.proposalDepartment),
        proposalType: toModelList<ProposalType>(value.proposalType),
        deleted: false,
      };

      await API.PROPOSAL.save(proposal);
    } else {
      console.log(result.error);
      form.setAllTouched();
    }
  }, [form]);

  return (
    <>
      <div className="overflow-auto p-4">
        <Form className="w-full grid grid-cols-2 gap-5 gap-x-8" autoComplete="off">
          <TextInput {...form.props('proposalReferanceNumber')} type="text" label="Teklif Referans Numarası" isDisabled />
          <FileInput name="name" type="text" label="Ek Dosyalar" />

          <DatePicker {...form.props('proposalDate')} label="Gönderilme Tarihi" isRequired />
          <DatePicker {...form.props('proposalDateOfValidity')} label="Geçerlilik Son Tarihi" isRequired />

          <TextInput {...form.props('proposalCost')} type="number" label="Ücret" />
          <ComboBox {...form.props('proposalCostDiscount')} label="İndirim (%)" defaultItems={proposalCostDiscountType} itemKey="id" itemLabel="detail" isRequired />

          <ComboBox {...form.props('proposedContact')} label="Teklif Verilen" defaultItems={contactList} itemKey="id" itemLabel="contactName" isRequired />
          <ComboBox {...form.props('proposer')} label="Teklif Veren" defaultItems={employeeList} itemKey="id" itemLabel="fullname" />

          <SelectListBox {...form.props('proposalDepartment')} label="İlgili Olduğu Bölüm/Birim" defaultItems={departmentList} itemKey="id" itemLabel="detail" isRequired />
          <SelectListBox {...form.props('proposalType')} label="Teklif Türü" defaultItems={proposalType} itemKey="id" itemLabel="detail" isRequired />

          <ComboBox {...form.props('proposalStatus')} label="Teklif Durumu" defaultItems={aktifPasif} itemKey="id" itemLabel="name" isRequired />
          <ComboBox {...form.props('realized')} label="İşe Dönüştü mü?" defaultItems={evetHayir} itemKey="id" itemLabel="name" />

          <TextInput {...form.props('proposalComment')} type="text" label="Teklif Açıklaması" className="col-span-2" textArea isRequired />

          <Accordion header="Şablon Oluşturma" className="col-span-2" panelClass="grid grid-cols-2 gap-5 gap-x-8">
            <ComboBox label="Ön Yazı" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            <ComboBox label="Teklif Formatı" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            <ComboBox label="Danışmanlık" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            <ComboBox label="Eğitim" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            <ComboBox label="Özgeçmişler" defaultItems={evetHayir} itemKey="id" itemLabel="name" />
            <span></span>
          </Accordion>
        </Form>
      </div>
      <ModalFooter>
        <Button variant="secondary">Şablon Oluştur</Button>
        <Button onPress={save}>Kaydet</Button>
      </ModalFooter>
    </>
  );
};
