import { Employee } from "./Employee";

export type BaseLookup = Partial<{
  id: number;
  detail: string;
  deleted: boolean;
  orderBy: number;
}>;

export type AccountAgreement = BaseLookup;
export type AccountCategory = BaseLookup;
export type AccountGroup = BaseLookup;
export type AccountIndustry = BaseLookup;
export type AccountInformedBy = BaseLookup;
export type AccountOwner = BaseLookup;
export type AccountPaymentType = BaseLookup;
export type AccountStatus = BaseLookup;
export type AccountTurnover = BaseLookup;
export type AccountType = BaseLookup;
export type AccountWorkingStatus = BaseLookup;
export type Currency = BaseLookup;
export type EmployeeNumber = BaseLookup;
export type ContactType = BaseLookup;
export type ContactTitle = BaseLookup;
export type TaskStatus = BaseLookup;
export type TaskProcessType = BaseLookup;
export type TaskAwaitingReminderType = BaseLookup;
export type TaskAwaitingReasonType = BaseLookup;
export type ProposalType = BaseLookup;
export type ProposalCostDiscountType = BaseLookup;
export type OpportunityType = BaseLookup;
export type OpportunityStatus = BaseLookup;
export type FeedBackType = BaseLookup;
export type FeedBackSubjectType = BaseLookup;
export type FeedBackStatus = BaseLookup;
export type FeedBackResult = BaseLookup;
export type ActivityType = BaseLookup;
export type PapersType = BaseLookup;

export type TaxOffice = BaseLookup &
  Partial<{
    code: string;
  }>;

export type City = Partial<{
  id: number;
  City: City;
  cityName: string;
}>;

export type Country = Partial<{
  id: number;
  shortName: string;
  name: string;
  nameEn: string;
  madrid: boolean;
  community: boolean;
  national: boolean;
  OAPI: boolean;
  benelux: boolean;
  orderBy: number;
  lahey: boolean;
}>;

export type Department = Partial<{
  id: number;
  department: Department;
  detail: string;
}>;

export type CustomerRepresentative = Partial<{
  id: number;
  employee: Employee;
}>;

export type ContactDepartmentConcerned = Partial<{
  id: number;
  department: Department;
}>;
