import * as API from 'api';
import { Button, ComboBox, DatePicker, Dialog, FileInput, Modal, ModalFooter, ModalHeader, SelectListBox, TextInput } from 'components';
import { aktifPasif, evetHayir } from 'constants';
import { useForm } from 'hooks';
import { useCallback } from 'react';
import { Form } from 'react-aria-components';
import {
  Account,
  Contact,
  Department,
  Employee,
  Opportunity,
  OpportunityForm,
  OpportunityFormable,
  OpportunityType,
  Proposal,
  ProposalForm,
  ProposalFormable,
  ProposalType,
} from 'types';
import { toModelList } from 'utils';

export interface FirsatKayitProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const FirsatKayitDialog = (props: FirsatKayitProps) => {
  return (
    <Modal isDismissable>
      <Dialog className="min-w-[960px] max-h-[90vh]">
        <ModalHeader header="Toplantı Kayıt" />
        <FirsatKayit {...props}></FirsatKayit>
      </Dialog>
    </Modal>
  );
};

export const FirsatKayit = ({ account, employeeList, departmentList, contactList }: FirsatKayitProps) => {
  const form = useForm<OpportunityFormable>(OpportunityForm, {});

  const [opportunityType] = API.SOZLUK.OPPORTUNITY_TYPE.useFetch({ init: true });
  const [opportunityStatus] = API.SOZLUK.OPPORTUNITY_STATUS.useFetch({ init: true });

  const save = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const value = result.data;

      const opportunity: Opportunity = {
        ...value,
        account: account,
        status: { id: value.status },
        customerRepr: { id: value.customerRepr },
        types: toModelList<OpportunityType>(value.types),
        dueDate: value.dueDate?.toString(),
        departmentResource: toModelList<Department>(value.departmentResource),
        employeeResource: toModelList<Employee>(value.employeeResource),
        deleted: false,
      };

      await API.OPPORTUNITY.save(opportunity);
    } else {
      console.log(result.error);
      form.setAllTouched();
    }
  }, [form]);

  return (
    <>
      <div className="overflow-auto p-4">
        <Form className="w-full grid grid-cols-2 gap-5 gap-x-8" autoComplete="off">
          <TextInput {...form.props('opportunityTitle')} type="text" label="Fırsat Adı" />
          <ComboBox {...form.props('status')} label="Fırsat Durumu" defaultItems={opportunityStatus} itemKey="id" itemLabel="detail" isRequired />

          <ComboBox {...form.props('realized')} label="Mevcuta Dönüştü mü?" defaultItems={evetHayir} itemKey="id" itemLabel="name" isRequired />
          <ComboBox {...form.props('customerRepr')} label="İlişkili Müşteri Temsilcisi" defaultItems={employeeList} itemKey="id" itemLabel="fullname" isRequired />

          <TextInput {...form.props('comment')} type="text" label="Açıklama" textArea isRequired />
          <SelectListBox {...form.props('types')} label="Fırsat Türü" defaultItems={opportunityType} itemKey="id" itemLabel="detail" isRequired />

          <DatePicker {...form.props('dueDate')} label="Fırsat Son Tarihi" isRequired />
          <span></span>

          <SelectListBox {...form.props('departmentResource')} label="Bölüm/Birim" defaultItems={departmentList} itemKey="id" itemLabel="detail" isRequired />
          <SelectListBox {...form.props('employeeResource')} label="Kişiler" defaultItems={employeeList} itemKey="id" itemLabel="fullname" isRequired />

          <FileInput name="name" type="text" label="Ek Dosyalar" />
        </Form>
      </div>
      <ModalFooter>
        <Button onPress={save}>Kaydet</Button>
      </ModalFooter>
    </>
  );
};
