import { ArrowUp, Loader2 } from 'lucide-react';
import { HTMLAttributes } from 'react';
import {
  Cell as AriaCell,
  Column as AriaColumn,
  Row as AriaRow,
  Table as AriaTable,
  TableHeader as AriaTableHeader,
  TableProps as AriaTableProps,
  Button,
  CellProps,
  Collection,
  ColumnProps,
  Group,
  RowProps,
  TableHeaderProps,
  composeRenderProps,
  useTableOptions,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { composeTailwindRenderProps, focusRing } from '../utils';
import { Checkbox } from './Checkbox';

export interface TableProps extends AriaTableProps {
  loading?: boolean;
}

const tableContainer = tv({
  base: 'overflow-x-auto relative border border-gray-300 rounded',
  variants: {
    loading: {
      true: 'w-max overflow-x-hidden',
    },
  },
});

const table = tv({
  extend: focusRing,
  base: 'w-full rounded relative border-gray-300',
});

export function Table(props: TableProps) {
  return (
    <div className={tableContainer({ loading: props.loading })}>
      <AriaTable {...props} aria-label="table" className={composeRenderProps(props.className, (className, renderProps) => table({ ...renderProps, className }))} />
      {props.loading && (
        <div className="absolute top-0 left-0 z-10 w-full h-full flex items-center justify-center bg-gray-400/10 backdrop-blur-xxs pt-6">
          <Loader2 size={40} className="animate-spin stroke-theme-400" />
        </div>
      )}
    </div>
  );
}

export function Column(props: ColumnProps) {
  return (
    <AriaColumn
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'border border-gray-300 p-2 text-center font-semibold bg-gray-100 text-gray-700 cursor-default border-t-0 first:border-l-0 last:border-r-0 ',
      )}
    >
      {composeRenderProps(props.children, (children, { allowsSorting, sortDirection }) => (
        <div className="flex items-center">
          <Group role="presentation" tabIndex={-1} className="w-full flex items-center justify-center gap-1 outline-0">
            <span className="truncate">{children}</span>
            {allowsSorting && (
              <span className={`w-4 h-4 flex items-center justify-center transition ${sortDirection === 'descending' ? 'rotate-180' : ''}`}>
                {sortDirection && <ArrowUp aria-hidden className="w-4 h-4 text-gray-500" />}
              </span>
            )}
          </Group>
        </div>
      ))}
    </AriaColumn>
  );
}

export function TableHeader<T extends object>(props: TableHeaderProps<T>) {
  let { selectionBehavior, selectionMode, allowsDragging } = useTableOptions();

  return (
    <AriaTableHeader {...props} className={twMerge('sticky -top-2.5 z-10 bg-gray-100/60 rounded-t-lg border-b', props.className)}>
      {/* Add extra columns for drag and drop and selection. */}
      {allowsDragging && <Column />}
      {selectionBehavior === 'toggle' && <Column>{selectionMode === 'multiple' && <Checkbox slot="selection" />}</Column>}
      <Collection items={props.columns}>{props.children}</Collection>
    </AriaTableHeader>
  );
}

const rowStyles = tv({
  extend: focusRing,
  base: 'group/row relative cursor-default -outline-offset-2 text-gray-900 disabled:text-gray-300  hover:bg-gray-100 selected:bg-theme-100 selected:hover:bg-theme-200 transition-colors duration-300',
});

export function Row<T extends object>({ id, columns, children, ...otherProps }: RowProps<T>) {
  let { selectionBehavior, allowsDragging } = useTableOptions();

  return (
    <AriaRow id={id} {...otherProps} className={rowStyles}>
      {allowsDragging && (
        <Cell>
          <Button slot="drag">≡</Button>
        </Cell>
      )}
      {selectionBehavior === 'toggle' && (
        <Cell>
          <Checkbox slot="selection" />
        </Cell>
      )}
      <Collection items={columns}>{children}</Collection>
    </AriaRow>
  );
}

const cell = tv({
  extend: focusRing,
  base: 'border p-2 truncate text-center border-gray-300 first:border-l-0 last:border-l-0 ',
});

export function Cell(props: CellProps) {
  return <AriaCell {...props} className={composeRenderProps(props.className, (className, renderProps) => cell({ ...renderProps, className }))} />;
}

export interface TableEmptyMessageProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
}

export function TableEmptyMessage(props: TableEmptyMessageProps) {
  return (
    <div {...props} className="bg-gray-50 flex items-center justify-center tracking-wider py-12">
      {!props.loading && <div className="bg-gray-100 border border-gray-300  text-slate-600 font-semibold rounded p-3 px-6">Kayıt yok.</div>}
    </div>
  );
}
