import { z } from 'zod';


export type FormFieldProps = {
  value: any;
  errorMessage: string;
  onChange: (feildValue: unknown) => void;
  isInvalid: boolean;
}

export type FormHook<T extends object> = {
  value: Partial<T>;
  selected: Partial<T> | undefined;
  valid: boolean;
  touchedAndInvalid: boolean;
  errors: Record<string,string>;
  validationErrors: Record<string,string>;
  toucheds: Record<string,boolean>;
  valids: Record<string,boolean>;
  parse: () => z.SafeParseReturnType<Partial<T>, Partial<T>>;
  setValue : React.Dispatch<React.SetStateAction<Partial<T>>>;
  setField : (fieldName: keyof T) => (feildValue: unknown) => void;
  setAllTouched: () => void;
  reset: () => void;
  fill: (newValue: Partial<T>) => void;
  patch: (newValue: Partial<T>) => void;
  props: (fieldName: keyof T) => FormFieldProps
}