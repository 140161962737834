export * from './menu';
export * from './request';
export * from './form';
export * from './sozluk';
export * from './Account';
export * from './Employee';
export * from './Adress';
export * from './Contact';
export * from './Activity';
export * from './CustomerSatisfactionForm';
export * from './FeedBack';
export * from './FeeList';
export * from './Opportunity';
export * from './Proposal';
export * from './Task';
export * from './AccountComment';
export * from './CrmFiles';
export * from './Papers';

export type KullaniciModel = Partial<{
  ad: string;
  kayitTarihi: string;
  kullaniciAdi: string;
  kullaniciId: number;
  kullaniciSifre: string;
  soyad: string;
  telefonNumarasi: string;
  employeeId: number;
}>;
