import { useFetch } from 'hooks';
import { Proposal, UseFetchOptions } from 'types';
import { request } from 'utils';

export default {
  useFetchById: (config: UseFetchOptions, id: number) => useFetch<Proposal[]>('/proposal/get-proposal-by-id', { method: 'POST', initParams: { id }, ...config }),
  useFetchByAccountId: (config: UseFetchOptions, accountId?: number) =>
    useFetch<Proposal[]>('/proposal/get-proposal-list-by-account-id', { method: 'POST', initParams: { accountId }, ...config }),
  save: async (proposal: Proposal) => request('/proposal/save', 'POST', proposal),
  delete: async (proposal: Proposal) => request('/proposal/delete', 'POST', null, { id: proposal.id }),
};
