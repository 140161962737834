import { useCallback, useEffect, useState } from 'react';
import { UseFetchOptions } from 'types';
import { objToHashSymbol, request } from 'utils';

const defaultOptions: UseFetchOptions = {
  init: false,
  method: 'GET',
  initBody: null,
  initParams: null,
};

const cacheToggle = false;

const cacheUrls: string[] = [];

type RequestCache = {
  time: number;
  body: any;
  params: any;
  data: any;
};

const cached: Record<symbol, RequestCache> = {};

export const useFetch = <T>(url: string, options = defaultOptions): [T | undefined, CallableFunction, boolean, CallableFunction, any] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const requestCallback = useCallback(
    async (data: any, params: any) => {
      setLoading(true);

      const response = await request(url, options.method, data, params, options, true);

      setLoading(false);
      if (response.ok) {
        let responseData = response.data;

        if (options.mapper) {
          responseData = responseData.map(options.mapper);
        }

        cached[Symbol.for(url)] = {
          time: new Date().getTime(),
          body: objToHashSymbol(data),
          params: objToHashSymbol(params),
          data: responseData,
        };
        setData(responseData);
      } else {
        setData(undefined);
        setError(response.error);
      }
    },
    [url, setData, setLoading, setError],
  );

  const resetData = useCallback(() => {
    setData(undefined);
  }, [setData]);

  useEffect(() => {
    if (options.init) {
      const cache = cached[Symbol.for(url)];

      if (cacheToggle && cache && cacheUrls.includes(url) && cache.body === objToHashSymbol(options.initBody) && cache.params === objToHashSymbol(options.initParams)) {
        setData(cache.data);
      } else {
        requestCallback(options.initBody, options.initParams);
      }
    }
  }, []);

  return [data, requestCallback, loading, resetData, error];
};
