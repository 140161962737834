import { CalendarDate } from '@internationalized/date';
import { Account } from './Account';
import { Employee } from './Employee';
import { Department, ProposalCostDiscountType, ProposalType } from './sozluk';
import { z } from 'zod';

export type Proposal = Partial<{
  id: string;
  proposalAccount: Account;
  proposalReferanceNumber: string;
  proposalDate: string;
  proposalDateOfValidity: string;
  proposedContact: string;
  proposalDepartment: Department[];
  proposer: Employee;
  proposalComment: string;
  proposalStatus: boolean;
  realized: boolean;
  // List<ProposalDocument> proposalDocument: string;
  deleted: boolean;
  version: string;
  createdBy: Employee;
  proposalCost: string;
  proposalCostDiscount: ProposalCostDiscountType;
  proposalType: ProposalType[];
}>;

export type ProposalFormable = Partial<{
  proposalReferanceNumber: string;
  proposalDate: CalendarDate;
  proposalDateOfValidity: CalendarDate;
  proposalCost: string;
  proposalCostDiscount: number;
  proposedContact: string;
  proposer: number;
  proposalDepartment: number[];
  proposalType: number[];
  proposalStatus: boolean;
  realized: boolean;
  proposalComment: string;
}>;

export const ProposalForm = z.object({
  proposalReferanceNumber: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  proposalDate: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  proposalDateOfValidity: z.custom((val: unknown) => Boolean(val), 'Zorunludur'),
  proposalCost: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  proposalCostDiscount: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  proposedContact: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, 'Zorunludur'),
  proposer: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  proposalDepartment: z.array(z.number()).nullish(),
  proposalType: z.array(z.number()).nullish(),
  proposalStatus: z.boolean(),
  realized: z.boolean(),
  proposalComment: z.string(),
});