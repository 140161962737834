import * as API from 'api';
import { Accordion, Button, Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { BadgePlus } from 'lucide-react';
import { useEffect } from 'react';
import { DialogTrigger, TableBody } from 'react-aria-components';
import { Account, Contact, Department, Employee } from 'types';
import { formatDate } from 'utils';
import { GeriBildirimKayitDialog } from './GeriBildirimKayit';

export interface GeriBildirimlerProps {
  account?: Account;
  employeeList?: Employee[];
  departmentList?: Department[];
  contactList?: Contact[];
}

export const GeriBildirimler = (props: GeriBildirimlerProps) => {
  const [list, getList, loading] = API.FEED_BACK.useFetchByAccountId({});

  useEffect(() => {
    if (props.account) {
      getList(null, { accountId: props.account.id });
    }
  }, [props.account, getList]);

  const tableList = usePageTableList({ list, loading });

  return (
    <Accordion header="Geri Bildirimler" open>
      <div className="mb-2">
        <DialogTrigger>
          <Button variant="secondary">
            <BadgePlus size={18} />
            Ekle
          </Button>
          <GeriBildirimKayitDialog {...props} />
        </DialogTrigger>
      </div>
      <div className="grid gap-1">
        <Table {...tableList}>
          <TableHeader>
            <Column isRowHeader>No</Column>
            <Column>Takip Numarası</Column>
            <Column>Tarih</Column>
            <Column>Sahibi</Column>
            <Column>İlgili</Column>
            <Column>Durumu</Column>
          </TableHeader>

          <TableBody {...tableList}>
            {(row) => (
              <Row key={row.id}>
                <Cell>{row.id}</Cell>
                <Cell>{row.trackingNumber}</Cell>
                <Cell>{formatDate(row.feedBackDate)}</Cell>
                <Cell>
                  {row.feedBackContact?.contactName} {row.feedBackContact?.contactSurname}
                </Cell>
                <Cell>
                  {row.assignedEmployee?.name} {row.assignedEmployee?.surname}
                </Cell>
                <Cell>{row.feedBackStatus?.detail}</Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        <Paginator {...tableList} />
      </div>
    </Accordion>
  );
};
