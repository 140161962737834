import { DateFormatter, parseDate, parseDateTime } from '@internationalized/date';


export const parseDateSafe = (dateString?: string) => {
  if (dateString) {
    try {
      return parseDate(dateString);
    } catch {
      return undefined;
    }
  }

  return undefined;
};

export const parseDateTimeSafe = (dateTimeString?: string) => {
  if (dateTimeString) {
    try {
      return parseDateTime(dateTimeString);
    } catch {
      return undefined;
    }
  }

  return undefined;
};

const formatter = new DateFormatter("tr-TR")


export const formatDate = (dateTimeString?: string) => {
  if (dateTimeString) {
    try {
      return formatter.format(new Date(dateTimeString));
    } catch {
      return '';
    }
  }

  return '';

};