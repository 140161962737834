import { Cell, Column, Paginator, Row, Table, TableHeader } from 'components';
import { usePageTableList } from 'hooks';
import { TableBody } from 'react-aria-components';
import { Account, Task } from 'types';
import * as API from 'api';
import { useEffect } from 'react';
import { formatDate } from 'utils';

export interface GorevlerProps {
  account?: Account;
}

export const Gorevler = (props: GorevlerProps) => {
  const { account } = props;

  const [list, getList, loading] = API.TASK.useFetchByAccountId({});

  const tableList = usePageTableList({
    list,
    loading,
  });

  useEffect(() => {
    if (account) {
      getList(null, { accountId: account.id });
    }
  }, [account, getList]);

  return (
    <div className="grid gap-1">
      <Table className="w-full" sortDescriptor={tableList.sortDescriptor} onSortChange={tableList.setSortDescriptor}>
        <TableHeader>
          <Column isRowHeader id="id" allowsSorting>
            Görev
          </Column>
          <Column>Sorumlu</Column>
          <Column allowsSorting id="dueDate">
            Son Tarih
          </Column>
          <Column>Yasal Son Tarih</Column>
          <Column id="taskStatus.detail" allowsSorting>
            Durum
          </Column>
        </TableHeader>

        <TableBody {...tableList}>
          {(row) => (
            <Row key={row.id}>
              <Cell key="gorev" className="text-theme-500 font-semibold hover:text-theme-600 cursor-pointer">
                {row.id}
              </Cell>
              <Cell>
                {row.responsibleUser?.name} {row.responsibleUser?.surname}
              </Cell>
              <Cell>{formatDate(row.dueDate)}</Cell>
              <Cell>{formatDate(row.finalDueDate)}</Cell>
              <Cell>{row.taskStatus?.detail}</Cell>
            </Row>
          )}
        </TableBody>
      </Table>
      <Paginator {...tableList} />
    </div>
  );
};
